import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Loader from "components/Loader";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  signup,
  clearLoginError,
  setBase,
} from "redux/actions/onboardingAction";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function Cover() {
  const loading = useSelector((state) => state.onboardingReducer.loading);
  const error = useSelector((state) => state.onboardingReducer.error);
  const success = useSelector((state) => state.onboardingReducer.success);

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [firstname, setFirstname] = React.useState();
  const [lastname, setLastname] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [password, setPassword] = React.useState();
  const [password2, setPassword2] = React.useState();

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    handleClose();
    setAgree((prevAgree) => !prevAgree);
  };
  const handleSignup = () => {
    const user = {
      firstname,
      surname: lastname,
      email,
      phone,
      password,
      password2,
      agree,
      company_id: window.location.hostname,
    };
    dispatch(signup(user));
  };
  const descriptionElementRef = React.useRef(null);
  const alertContent = (msg) => (
    <MDTypography variant="body2" color="white">
      {msg}
    </MDTypography>
  );
  React.useEffect(() => {
    dispatch(clearLoginError());
    dispatch(setBase("user"));
  }, []);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <CoverLayout image={bgImage}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
              )
              .join("\n")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton onClick={handleAgree}>Agree</MDButton>
        </DialogActions>
      </Dialog>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us now
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and other details to register
          </MDTypography>
        </MDBox>
        <MDBox pt={0} pb={1} px={3}>
          <MDBox component="form" role="form">
            <MDBox pb={1}>
              <MDInput
                type="text"
                label="First Name"
                onChange={(e) => setFirstname(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox pb={1}>
              <MDInput
                type="text"
                label="Last Name"
                onChange={(e) => setLastname(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox pb={1}>
              <MDInput
                type="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox pb={1}>
              <MDInput
                type="phone"
                label="Phone"
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox pb={1}>
              <MDInput
                type="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox pb={1}>
              <MDInput
                type="password"
                label="Password again"
                onChange={(e) => setPassword2(e.target.value)}
                fullWidth
              />
            </MDBox>

            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox checked={agree} onChange={handleAgree} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                onClick={handleClickOpen("paper")}
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton
                onClick={handleSignup}
                variant="gradient"
                color="info"
                fullWidth
              >
                Register
              </MDButton>
              {loading && <Loader />}
              {error && (
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDAlert color="error" dismissible>
                    {alertContent(error)}
                  </MDAlert>
                </MDBox>
              )}
              {success && (
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDAlert color="success" dismissible>
                    {alertContent(success)}
                  </MDAlert>
                </MDBox>
              )}
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
