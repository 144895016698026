import * as types from "../actions/ActionTypes";

const userReducer = (state = { visits: [], messages: [] }, action) => {
  switch (action.type) {
    case types.ADD_VISIT_START:
      return Object.assign({}, state, {
        loading: true,
      });
    case types.ADD_VISIT_SUCCESS:
      return Object.assign({}, state, {
        response: action.payload,
      });
    case types.ADD_VISIT_END:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case types.GET_MESSAGES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        messages: action.payload,
        error: null,
      });
    case types.OPEN_MESSAGE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        openedmessage: action.payload,
        error: null,
      });
    case types.CLEAR_MESSAGE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        openedmessage: null,
        error: null,
      });
    case types.CLEAR_VISIT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        visits: [],
        error: null,
      });

    case types.GET_VISITS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        visits: action.payload,
        error: null,
      });
    default:
      return state;
  }
};
export default userReducer;
