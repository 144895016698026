/* eslint-disable react/prop-types */
import * as React from "react";
// Soft UI Dashboard React components
import { useSelector } from "react-redux";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import moment from "moment";
import MDProgress from "components/MDProgress";
import MDBadge from "components/MDBadge";
import IconButton from "@mui/material/IconButton";
import NotificationItem from "examples/Items/NotificationItem";
import Button from "@mui/material/Button";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
// Images

import Slide from "@mui/material/Slide";

import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function data(handleOpenMenu) {
  const visits = useSelector((state) => state.userReducer?.visits);
  const [data, setData] = React.useState([]);

  const handleShowEdit = () => {
    handleCloseMenu();
    showEdit(true);
  };

  const handleCloseEdit = () => {
    showEdit(false);
  };

  const handleClickOpen = () => {
    handleCloseMenu();
    setOpen(true);
  };
  const handleSuspendOpen = () => {
    handleCloseMenu();
    setOpenSuspend(true);
  };
  const handleSuspendClose = () => {
    setOpenSuspend(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dateToFromNowDaily = (myDate) => {
    var fromNow = moment(myDate).fromNow();

    return moment(myDate).calendar(null, {
      lastWeek: "[Last] dddd",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  };

  React.useEffect(() => {
    setData(
      visits.map((visit) => ({
        author: (
          <Author image={team2} name={visit.name} email={visit.address} />
        ),
        code: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {visit.secret_code}
          </MDTypography>
        ),
        function: (
          <Job title={visit.phone} description={"Purpose: " + visit.purpose} />
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={
                visit.status === 0
                  ? "Pending"
                  : visit.status === 1
                  ? "Approved"
                  : visit.status === 3
                  ? "Checked Out"
                  : "Declined"
              }
              color={
                visit.status === 0
                  ? "warning"
                  : visit.status === 1
                  ? "success"
                  : visit.status === 3
                  ? "info"
                  : "error"
              }
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        owner: (
          <Job
            title={visit.visitinguser}
            description={"Department: " + visit.department}
          />
        ),
        timein: (
          <Job
            title={visit.sign_in_time}
            description={"Time Out: " + visit.sign_out_time}
          />
        ),

        employed: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {new Date(visit.createdAt).toDateString()}
          </MDTypography>
        ),
        // action: (
        //   <>
        //     <IconButton
        //       size="small"
        //       disableRipple
        //       color="inherit"
        //       sx={navbarIconButton}
        //       aria-controls="notification-menu"
        //       aria-haspopup="true"
        //       variant="contained"
        //       onClick={(event) => handleOpenMenu(event, visit.id, visit.status)}
        //     >
        //       <Icon>more_vert</Icon>
        //     </IconButton>
        //   </>
        // ),
      }))
    );
  }, [visits]);

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      {
        Header: "Full Name & Email",
        accessor: "author",
        width: "45%",
        align: "left",
      },
      { Header: "Secret Code", accessor: "code", align: "left" },
      { Header: "Phone", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Whom to see", accessor: "owner", align: "center" },
      { Header: "Time in", accessor: "timein", align: "center" },
      { Header: "Date Added", accessor: "employed", align: "center" },
      //  { Header: "action", accessor: "action", align: "center" },
    ],

    rows: data,
    status: data.status,
    // author: (
    //   <Author
    //     image={team2}
    //     name="John Michael"
    //     email="john@creative-tim.com"
    //   />
    // ),
    // function: <Job title="Manager" description="Organization" />,
    // status: (
    //   <MDBox ml={-1}>
    //     <MDBadge
    //       badgeContent="online"
    //       color="success"
    //       variant="gradient"
    //       size="sm"
    //     />
    //   </MDBox>
    // ),
    // employed: (
    //   <MDTypography
    //     component="a"
    //     href="#"
    //     variant="caption"
    //     color="text"
    //     fontWeight="medium"
    //   >
    //     23/04/18
    //   </MDTypography>
    // ),
    // action: (
    //   <>
    //     <IconButton
    //       size="small"
    //       disableRipple
    //       color="inherit"
    //       sx={navbarIconButton}
    //       aria-controls="notification-menu"
    //       aria-haspopup="true"
    //       variant="contained"
    //       onClick={handleOpenMenu}
    //     >
    //       <Icon>more_vert</Icon>
    //     </IconButton>
    //     {renderMenu()}
    //     {renderDeleteDialog()}
    //     {renderSuspendDialog()}
    //   </>
    // ),
  };
}
