import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";

function About() {
  return (
    <div class="wrapper">
      <header class="header-5">
        <Header />
        {/*  <div class="page-header">
            <div class="container">
             <div class="row align-items-center text-left">
              <div class="col-lg-5 col-12">
                <h1 class="display-3">
                  With SmartVisit,
                  <span class="text-primary"> Secure your space.</span>
                </h1>
                <p class="lead pb-4">
                  We aim high at being focused on building relationships with
                  our clients and community. Using our creative gifts drives
                  this foundation.
                </p>
                <div class="row row-input">
                  <div class="col-sm-8 col-12">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="signupSrEmail"
                      placeholder="Your email"
                      aria-label="Your email"
                    />
                  </div>
                  <div class="col-sm-4 col-12 pl-lg-0">
                    <button type="submit" class="btn btn-primary btn-block">
                      More
                    </button>
                  </div>
                </div>
              </div> 
              <div class="col-lg-7 col-12 pl-0">
                <img
                  class="ml-lg-5"
                  src="./assets/img/banner3.jpeg"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>*/}
      </header>
      <div class="section features-1">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mx-auto text-center">
              <span class="badge badge-primary badge-pill mb-3">Insight</span>
              <h3 class="display-3">ABOUT</h3>
              <p class="lead" style={{textAlign: "justify"}}>
                SmartVisit® is a visitors management system designed for public
                and private organization to manage their visitor’s invitation,
                verification and approve entry and exit.
              </p>
              <p class="lead" style={{textAlign: "justify"}}>
                With SmartVisit®, safe,
                seamless and successful visitors management is guaranteed and
                the organizations security team gets all the information needed
                to manage the gate efficiently and effectively. SmartVisit® is
                designed to foster better interaction within organisations with
                strict security protocols and workplaces that are highly
                confidential.
              </p>
              <p class="lead" style={{textAlign: "justify"}}>
                Users can register their visitor’s and send an
                entry code to the visitor, either by text or QR code, the
                security at the gate verifies this code on the visitor’s arrival
                and then entry is approved.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section
        class="pricing-5"
        id="pricing-6"
        style={{
          backgroundImage: `url("./assets/img/kit/pro/ill/bg_pricing5.html")`,
        }}
      >
        <div class="container pt">
          <div>
            <div class="row">
              <div class="col-md-6">
                <div class="card card-pricing bg-white border-0 text-center mb-4">
                  <div class="card-header bg-transparent">
                    <h6 class="text-uppercase ls-1 py-3 mb-0">Silver</h6>
                  </div>
                  <div class="card-body">
                    {/* <div class="display-2">₦ 2000</div>
                    <span>per month</span> */}
                    <ul class="list-unstyled my-4">
                      <li class="align-items-center">
                        <b class="text-primary">Full</b> <span>Reports</span>
                      </li>
                      <li class="align-items-center">
                        <b class="text-primary">50</b> <span>Users</span>
                      </li>
                      <li class="align-items-center">
                        <b class="text-primary">Premium</b> <span>Support</span>
                      </li>
                    </ul>
                  </div>
                  <div class="card-footer bg-transparent">
                    <a href="/contact">Request a demo</a>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card card-pricing bg-white border-0 text-center mb-4">
                  <div class="card-header bg-transparent">
                    <h6 class="text-uppercase ls-1 py-3 mb-0">Gold</h6>
                  </div>
                  <div class="card-body">
                    {/* <div class="display-2">₦ 1500</div>
                    <span>per month</span> */}
                    <ul class="list-unstyled my-4">
                      <li class="align-items-center">
                        <b class="text-primary">Full</b> <span>Reports</span>
                      </li>
                      <li class="align-items-center">
                        <b class="text-primary">250</b> <span>Users</span>
                      </li>
                      <li class="align-items-center">
                        <b class="text-primary">Premium</b> <span>Support</span>
                      </li>
                    </ul>
                  </div>
                  <div class="card-footer bg-transparent">
                    <a href="/contact">Request a demo</a>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card card-pricing bg-white border-0 text-center mb-4">
                  <div class="card-header bg-transparent">
                    <h6 class="text-uppercase ls-1 py-3 mb-0">Diamond</h6>
                  </div>
                  <div class="card-body">
                    {/* <div class="display-2">₦ 1000</div>
                    <span>per month</span> */}
                    <ul class="list-unstyled my-4">
                      <li class="align-items-center">
                        <b class="text-primary">Full</b> <span>Reports</span>
                      </li>
                      <li class="align-items-center">
                        <b class="text-primary">450</b> <span>Users</span>
                      </li>
                      <li class="align-items-center">
                        <b class="text-primary">Premium</b> <span>Support</span>
                      </li>
                    </ul>
                  </div>
                  <div class="card-footer bg-transparent">
                    <a href="/contact">Request a demo</a>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card card-pricing bg-white border-0 text-center mb-4">
                  <div class="card-header bg-transparent">
                    <h6 class="text-uppercase ls-1 py-3 mb-0">Platinum</h6>
                  </div>
                  <div class="card-body">
                    {/* <div class="display-2">₦ 800</div>
                    <span>per month</span> */}
                    <ul class="list-unstyled my-4">
                      <li class="align-items-center">
                        <b class="text-primary">Full</b> <span>Reports</span>
                      </li>
                      <li class="align-items-center">
                        <b class="text-primary">600</b> <span>Users</span>
                      </li>
                      <li class="align-items-center">
                        <b class="text-primary">No time</b>{" "}
                        <span>Tracking</span>
                      </li>
                    </ul>
                  </div>
                  <div class="card-footer bg-transparent">
                    <a href="/contact">Request a demo</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default About;
