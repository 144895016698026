// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";
import { useDispatch } from "react-redux";
import { deleteMessage } from "redux/actions/userActions";

function Bill({ id, name, company, email, vat, noGutter }) {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleDelete = (id) => {
    dispatch(deleteMessage(id));
  };

  return (
    <>
      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={noGutter ? 0 : 1}
        mt={2}
      >
        <MDBox width="100%" display="flex" flexDirection="column">
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Date and Time:&nbsp;&nbsp;&nbsp;
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {company}
              </MDTypography>
            </MDTypography>
          </MDBox>

          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
            mt={4}
          >
            <MDTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {name}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        mb={2}
      >
        <MDTypography
          variant="button"
          fontWeight="medium"
          textTransform="capitalize"
        ></MDTypography>

        <MDBox
          display="flex"
          alignItems="center"
          mt={{ xs: 2, sm: 0 }}
          ml={{ xs: -1.5, sm: 0 }}
        >
          <MDButton
            onClick={() => handleDeletet(id)}
            variant="text"
            color="error"
          >
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
