import React from "react";
import MDBox from "components/MDBox";
import loader from "assets/images/loader.gif";

function Loader() {
  return (
    <MDBox
      component="div"
      pt={1}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <MDBox component="img" src={loader} alt="Loading" width="2rem" />
    </MDBox>
  );
}

export default Loader;
