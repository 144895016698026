import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { clearMessages } from "redux/actions/uiActions";

const TransitionLeft = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

function ToastContainer() {
  const dispatch = useDispatch();
  const success = useSelector((state) => state.uiReducer?.success);
  const error = useSelector((state) => state.uiReducer?.error);

  const handleCloseSnack = () => {
    dispatch(clearMessages());
  };

  return (
    <>
      {success && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={Boolean(success)}
          autoHideDuration={2000}
          onClose={handleCloseSnack}
          TransitionComponent={TransitionLeft}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={"success"}
            sx={{ width: "100%" }}
          >
            {success ? String(success) : null}
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={Boolean(error)}
          autoHideDuration={2000}
          onClose={handleCloseSnack}
          TransitionComponent={TransitionLeft}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={"error"}
            sx={{ width: "100%" }}
          >
            {error ? String(error) : null}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ToastContainer;
