function Footer({ light }) {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row row-grid align-items-center mb-5">
            <div className="col-lg-6">
              <h3 className="text-primary font-weight-light mb-2">
                Stay updated on News and Events!
              </h3>
              <h4 className="mb-0 font-weight-light">
                Let's get in touch on any of these platforms.
              </h4>
            </div>
            <div className="col-lg-6 text-lg-center btn-wrapper">
              <button
                target="_blank"
                href="https://twitter.com/creativetim"
                rel="nofollow"
                className="btn btn-icon-only btn-twitter rounded-circle"
                data-toggle="tooltip"
                data-original-title="Follow us"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-twitter"></i>
                </span>
              </button>
              <button
                target="_blank"
                href="https://www.facebook.com/CreativeTim/"
                rel="nofollow"
                className="btn-icon-only rounded-circle btn btn-facebook"
                data-toggle="tooltip"
                data-original-title="Like us"
              >
                <span className="btn-inner--icon">
                  <i className="fab fa-facebook"></i>
                </span>
              </button>
              <button
                target="_blank"
                href="#"
                rel="nofollow"
                className="btn btn-icon-only btn-dribbble rounded-circle"
                data-toggle="tooltip"
                data-original-title="Follow us"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-instagram"></i>
                </span>
              </button>
            </div>
          </div>
          <hr />
          <div className="row align-items-center justify-content-md-between">
            <div className="col-md-6">
              <div className="copyright">
                &copy; 2020 Powered by{" "}
                <a href="https://www.taggedsecurity.com" target="_blank">
                  TTL
                </a>
                .
              </div>
            </div>
            <div className="col-md-6">
              <ul className="nav nav-footer justify-content-end">
                <li className="nav-item">
                  <a href="/faq" className="nav-link">
                    SmartVisit FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/downloads" className="nav-link">
                    SmartVisit Apps
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/data-protection"
                    className="nav-link"
                    target="_blank"
                  >
                    Data Protection
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
