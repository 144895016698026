import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
// Billing page components
import Bill from "layouts/notifications/components/Bill";

function BillingInformation() {
  const openedmessage = useSelector(
    (state) => state.userReducer?.openedmessage
  );

  return (
    <>
      {openedmessage && (
        <Card id="delete-account">
          <MDBox pt={3} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              {openedmessage.subject}
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2}>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
            >
              <Bill
                name={openedmessage.message}
                company={openedmessage.company}
                email={openedmessage.sender}
                person={openedmessage.sendername}
                phone={openedmessage.phone}
                vat="FRB1235476"
              />
            </MDBox>
          </MDBox>
        </Card>
      )}
    </>
  );
}

export default BillingInformation;
