import * as types from "../actions/ActionTypes";

const adminReducer = (
  state = { companies: [], users: [], adminusers: [] },
  action
) => {
  switch (action.type) {
    case types.CLEAR_CONFIRM:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        response: null,
      });
    case types.CONFIRM_VISIT_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        response: null,
      });
    case types.CONFIRM_VISIT_SUCCESS:
      return Object.assign({}, state, {
        response: action.payload,
        error: null,
      });
    case types.CONFIRM_VISIT_END:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case types.APPROVE_VISIT_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        response: null,
      });
    case types.APPROVE_VISIT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        response: null,
        error: null,
      });
    case types.APPROVE_VISIT_FAILED:
      return Object.assign({}, state, {
        loading: false,
        response: null,
        error: action.payload,
      });

    case types.DECLINE_VISIT_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        response: null,
      });
    case types.DECLINE_VISIT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        response: null,
        error: null,
      });
    case types.DECLINE_VISIT_FAILED:
      return Object.assign({}, state, {
        loading: false,
        response: null,
        error: action.payload,
      });

    case types.CHECKOUT_VISIT_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        response: null,
      });
    case types.CHECKOUT_VISIT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        response: null,
        error: null,
      });
    case types.CHECKOUT_VISIT_FAILED:
      return Object.assign({}, state, {
        loading: false,
        response: null,
        error: action.payload,
      });

    case types.GET_COMPANIES_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        companies: [],
      });
    case types.GET_COMPANIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        companies: action.payload,
        error: null,
      });
    case types.GET_USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        users: action.payload,
        error: null,
      });
    case types.GET_ADMINUSERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        adminusers: action.payload,
        error: null,
      });
    case types.CLEAR_USERS_SUCCESS:
      return Object.assign({}, state, {
        users: [],
        adminusers: [],
        error: null,
      });

    case types.GET_COMPANIES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case types.DELETE_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        companies: action.payload,
        error: null,
      });
    case types.DELETE_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        users: action.payload,
        error: null,
      });
    case types.DELETE_ADMINUSERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        adminusers: action.payload,
        error: null,
      });

    case types.ADD_ADMIN_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        success: null,
      });
    case types.ADD_ADMIN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        success: "Admin account has been successfully registered.",
      });
    case types.ADD_ADMIN_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        success: null,
      });
    default:
      return state;
  }
};
export default adminReducer;
