import axios from "axios";
import * as config from "../../config/config";
import * as types from "./ActionTypes";
// import { showAlert } from '../Helpers/navigationHelper';

export const getCompanies = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.GET_COMPANIES_START });
    axios
      .get(config.SERVER_DETAILS.getcompanies)
      .then((response) => {
        dispatch({
          type: types.GET_COMPANIES_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.GET_COMPANIES_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const suspendUser = (user, id) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });

    axios
      .put(config.SERVER_DETAILS.suspenduser, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.SUSPEND_USER_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: types.ACTION_SUCCESS,
          payload: response.data.message,
        });
        dispatch(getUsers(id));
      })

      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const deleteUser = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: types.ACTION_START });
    axios
      .delete(config.SERVER_DETAILS.deleteuser + `/${id}`)
      .then((response) => {
        let currentusers = getState().adminReducer.users;
        currentusers = currentusers.filter((user) => user.id !== id);
        dispatch({
          type: types.DELETE_USER_SUCCESS,
          payload: currentusers,
        });

        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "User Deleted Successfully",
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const suspendCompany = (company) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(config.SERVER_DETAILS.suspendcompany, JSON.stringify(company), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.SUSPEND_COMPANY_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: types.ACTION_SUCCESS,
          payload: response.data.message,
        });
        dispatch(getCompanies());
      })

      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const deleteCompany = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: types.ACTION_START });
    axios
      .delete(config.SERVER_DETAILS.deletecompany + `/${id}`)
      .then((response) => {
        let currentcompany = getState().adminReducer.companies;
        currentcompany = currentcompany.filter((company) => company.id !== id);
        dispatch({
          type: types.DELETE_COMPANY_SUCCESS,
          payload: currentcompany,
        });

        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Company Deleted Successfully",
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const clearConfirm = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.CLEAR_CONFIRM });
  };
};

export const confirmVisit = (user) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.CONFIRM_VISIT_START });
    axios
      .post(config.SERVER_DETAILS.confirmvisit, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.CONFIRM_VISIT_SUCCESS,
          payload: response.data,
        });
        dispatch({ type: types.CONFIRM_VISIT_END });
        //  dispatch(getInstallments({ email: response.data.email }));
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.CONFIRM_VISIT_END,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const approveVisit = (user) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.APPROVE_VISIT_START });
    axios
      .post(config.SERVER_DETAILS.approvevisit, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.APPROVE_VISIT_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Approved Successfully",
        });
        //  dispatch(getInstallments({ email: response.data.email }));
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.APPROVE_VISIT_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const declineVisit = (user) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.DECLINE_VISIT_START });
    axios
      .post(config.SERVER_DETAILS.declinevisit, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.DECLINE_VISIT_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Declined Successfully",
        });
        //  dispatch(getInstallments({ email: response.data.email }));
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.DECLINE_VISIT_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const checkOutVisit = (user) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.CHECKOUT_VISIT_START });
    axios
      .post(config.SERVER_DETAILS.checkoutvisit, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.CHECKOUT_VISIT_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Checked out Successfully",
        });
        //  dispatch(getInstallments({ email: response.data.email }));
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.CHECKOUT_VISIT_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const addCompany = (company) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_SUCCESS });
    axios
      .post(config.SERVER_DETAILS.addcompany, JSON.stringify(company), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Company Added Successfully",
        });
        dispatch(getCompanies());
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const updateCompany = (company) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_SUCCESS });
    axios
      .put(config.SERVER_DETAILS.addcompany, JSON.stringify(company), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Company Updated Successfully",
        });
        dispatch(getCompanies());
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const updateBilling = (billing) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_SUCCESS });
    axios
      .put(config.SERVER_DETAILS.updatebilling, JSON.stringify(billing), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Billing Updated Successfully",
        });
        dispatch(getCompanies());
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const clearUsers = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_USERS_SUCCESS,
    });
  };
};

export const getUsers = (companyID) => {
  return (dispatch, getState) => {
    dispatch({ type: types.ACTION_START });
    axios
      .get(config.SERVER_DETAILS.getusers + `/${companyID}`)
      .then((response) => {
        dispatch({
          type: types.GET_USERS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const getAdminUsers = (companyID) => {
  return (dispatch, getState) => {
    dispatch({ type: types.ACTION_START });
    axios
      .get(config.SERVER_DETAILS.getadminusers + `/${companyID}`)
      .then((response) => {
        dispatch({
          type: types.GET_ADMINUSERS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const addAdmin = (user) => {
  return (dispatch, getState) => {
    if (
      !user.email ||
      !user.phone ||
      !user.firstname ||
      !user.surname ||
      !user.password
    ) {
      dispatch({
        type: types.ADD_ADMIN_FAILED,
        payload: "Important field is left blank",
      });
      return;
    } else if (user.password !== user.password2) {
      dispatch({
        type: types.ADD_ADMIN_FAILED,
        payload: "Password mis-matched!",
      });
      return;
    }

    const headers = {
      "Content-Type": "application/json",
    };

    dispatch({ type: types.ADD_ADMIN_START });
    axios
      .post(config.SERVER_DETAILS.addadmin, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({ type: types.ADD_ADMIN_SUCCESS, payload: response.data });
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Admin User registered successfully",
        });
      })
      .catch((error) => {
        dispatch({
          type: types.ADD_ADMIN_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const clearList = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_LIST_SUCCESS,
    });
  };
};
