const PaymentReducer = (
  state = {
    amount: 0,
    usermail: '',
    installment: 0,
    bundlediscount: 0,
    bundleprice: 0
  },
  action
) => {
  switch (action.type) {
    case 'Make_Payment':
      return {
        amount: action.amount,
        usermail: action.usermail,
        installment: action.installment,
        bundlediscount: action.bundlediscount,
        bundleprice: action.bundleprice,
        charges: action.charges,
        bundleplan: action.bundleplan,
        bundlecategory: action.bundlecategory,
        noofshares: action.noofshares,
        paymenttype: action.paymenttype
      };
    // return state
    default:
      return state;
  }
};

export default PaymentReducer;
