import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Loader from "components/Loader";
import MDAlert from "components/MDAlert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/adminusers/data/authorsTableData";

import {
  getUsers,
  suspendUser,
  deleteUser,
  addCompany,
  updateCompany,
} from "redux/actions/adminActions";
import Slide from "@mui/material/Slide";
import {
  addAdmin,
  clearUsers,
  getAdminUsers,
} from "redux/actions/adminActions";
import {} from "redux/actions/adminActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AdminUsers() {
  const handleOpenMenu = (event, id, status) => {
    setCurrentID(id);
    setCurrentStatus(status);
    setOpenMenu(event.currentTarget);
  };
  const { columns, rows, id } = authorsTableData(handleOpenMenu);
  const companies = useSelector((state) => state.adminReducer?.companies);
  const logtype = useSelector((state) => state.onboardingReducer.type);
  const role = useSelector((state) => state.onboardingReducer.profile.type);
  const company_id = useSelector(
    (state) => state.onboardingReducer?.profile?.company_id
  );
  const loading = useSelector((state) => state.adminReducer.loading);
  const error = useSelector((state) => state.adminReducer.error);
  const success = useSelector((state) => state.adminReducer.success);

  const minicompanies = companies.map((company) => ({
    label: company.name,
    id: company.id,
    key: company.id,
  }));
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [currentCompanyID, setCurrentCompanyID] = React.useState();
  const [showEdit, setShowEdit] = React.useState(false);
  const [currentID, setCurrentID] = React.useState();
  const [currentStatus, setCurrentStatus] = React.useState();
  const [showDelete, setShowDelete] = React.useState(false);
  const [showSuspend, setShowSuspend] = React.useState(false);

  const [firstname, setFirstname] = React.useState();
  const [lastname, setLastname] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [password, setPassword] = React.useState();
  const [password2, setPassword2] = React.useState();
  const [adminType, setAdminType] = React.useState(2);
  const [companyID, setCompanyID] = React.useState();

  const [scroll, setScroll] = React.useState("paper");
  const [openMenu, setOpenMenu] = React.useState(false);

  const handleCloseMenu = () => setOpenMenu(false);

  const handleShowEdit = () => {
    handleCloseMenu();
    setShowEdit(true);
  };

  const alertContent = (msg) => (
    <MDTypography variant="body2" color="white">
      {msg}
    </MDTypography>
  );

  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const handleClickOpen = () => {
    handleCloseMenu();
    setOpen(true);
  };

  const handleSuspendClose = () => {
    setShowSuspend(false);
  };
  const handleShowDelete = () => {
    handleCloseMenu();
    setShowDelete(true);
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleShowSuspend = () => {
    handleCloseMenu();
    setShowSuspend(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCompany = (id) => {
    if (id) {
      setCurrentCompanyID(id);
      dispatch(clearUsers());
      dispatch(getAdminUsers(id));
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteUser(id));
    handleCloseDelete();
  };

  const handleSuspend = (id) => {
    const newstatus = currentStatus === 2 ? 1 : 2;
    const status = {
      newstatus,
      id,
    };
    dispatch(suspendUser(status, currentCompanyID));
    handleSuspendClose();
  };

  const handleSubmit = () => {
    const admin = {
      email: email,
      firstname: firstname,
      surname: lastname,
      phone: phone,
      password: password,
      password2: password2,
      company_id: companyID,
      admintype: adminType,
    };
    dispatch(addAdmin(admin));
  };

  const handleUpdate = () => {
    const company = {
      id: currentID,
      email: companyEmail,
      address: companyAddress,
      name: companyName,
      phone: companyPhone,
      subdomain: companySubdomain,
    };
    dispatch(updateCompany(company));
    handleCloseEdit();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    setCurrentCompanyID(company_id);
    dispatch(clearUsers());
    if (role !== 0) dispatch(getAdminUsers(company_id));
  }, []);
  React.useEffect(() => {
    if (success) handleClose();
  }, [success]);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <DashboardLayout>
      <Menu
        id="simple-menu"
        anchorEl={openMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
      >
        {/* <MenuItem onClick={() => handleShowEdit(currentID)}>Edit</MenuItem> */}
        <MenuItem onClick={handleShowSuspend}>
          {currentStatus === 2 ? "Activate" : "Suspend"}
        </MenuItem>
        <MenuItem onClick={handleShowDelete}>Delete</MenuItem>
      </Menu>
      <Dialog
        open={showDelete}
        onClose={handleCloseDelete}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete User?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to delete this user, this action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={() => handleDelete(currentID)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showSuspend}
        onClose={handleSuspendClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {currentStatus === 2 ? "Activate" : "Suspend"} User?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to{" "}
            {currentStatus === 2 ? "activate" : "suspend"} this user.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuspendClose}>Cancel</Button>
          <Button onClick={() => handleSuspend(currentID)} autoFocus>
            {currentStatus === 2 ? "Activate" : "Suspend"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"sm"}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add Admin User</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <MDBox pr={1}>
            <MDBox
              pt={2}
              pb={2}
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <MDBox>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={minicompanies}
                  onChange={(event, newValue) => {
                    if (newValue?.id) setCompanyID(newValue?.id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Company" />
                  )}
                />
              </MDBox>
              <MDBox>
                <MDInput
                  required
                  id="standard-error-helper-text"
                  label="First Name"
                  fullWidth
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </MDBox>
              <MDBox>
                <MDInput
                  required
                  fullWidth
                  id="standard-error-helper-text"
                  label="Last Name"
                  onChange={(e) => setLastname(e.target.value)}
                />
              </MDBox>
              <MDBox>
                <MDInput
                  required
                  id="standard-error-helper-text"
                  fullWidth
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox>
                <MDInput
                  required
                  fullWidth
                  id="standard-error-helper-text"
                  label="Phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </MDBox>
              <MDBox>
                <MDInput
                  required
                  id="standard-error-helper-text"
                  fullWidth
                  label="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>
              <MDBox>
                <MDInput
                  required
                  id="standard-error-helper-text"
                  fullWidth
                  type="password"
                  label="Password again"
                  onChange={(e) => setPassword2(e.target.value)}
                />
              </MDBox>
              <MDBox m={1}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Admin Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={adminType}
                    sx={{ height: 45, width: 250 }}
                    onChange={(e) => setAdminType(e.target.value)}
                  >
                    {role === 0 && <MenuItem value={0}>Root</MenuItem>}
                    <MenuItem value={2}>Gate Admin</MenuItem>
                    <MenuItem value={3}>Super Admin</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
        {loading && <Loader />}
        {error && (
          <MDBox mt={2} px={2} mb={1} textAlign="center">
            <MDAlert color="error" dismissible>
              {alertContent(error)}
            </MDAlert>
          </MDBox>
        )}
      </Dialog>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {!showEdit ? (
            <>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      All Admin Users in Company
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <MDBox
                      pb={2}
                      pr={2}
                      pl={2}
                      mb={2}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <MDBox></MDBox>
                      {logtype === "admin" && role === 0 && (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={minicompanies}
                          onChange={(event, newValue) => {
                            if (newValue?.id) handleChangeCompany(newValue?.id);
                          }}
                          sx={{ width: 300, height: 20 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Company" />
                          )}
                        />
                      )}

                      <MDButton
                        onClick={() => handleClickOpen("paper")}
                        variant="gradient"
                        color="dark"
                        sx={{ marginLeft: 1 }}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp; Add Admin User
                      </MDButton>
                    </MDBox>
                    {currentCompanyID && (
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    )}
                    <MDBox>
                      <br />
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Edit User
                  </MDTypography>
                </MDBox>
                <MDBox pt={2}>
                  <MDBox
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { ml: 4, mt: 2, width: "25ch" },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="standard-error-helper-text"
                        label="Company Name"
                        defaultValue="Hello World"
                      />
                    </MDBox>

                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="standard-error-helper-text"
                        label="Email"
                        defaultValue="Hello World"
                      />
                    </MDBox>
                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="standard-error-helper-text"
                        label="Phone"
                        defaultValue="Hello World"
                      />
                    </MDBox>
                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="standard-error-helper-text"
                        label="Address"
                        multiline
                        rows={4}
                        defaultValue="Hello World"
                      />
                    </MDBox>

                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="outlined-required"
                        label="Company Code"
                        defaultValue="Hello World"
                      />
                    </MDBox>
                    <MDBox pb={1}>
                      <MDInput
                        id="outlined-disabled"
                        label="Company Logo"
                        defaultValue="Hello World"
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox
                  pb={2}
                  pt={2}
                  pr={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MDButton
                    onClick={handleCloseEdit}
                    variant="gradient"
                    sx={{ marginRight: 1 }}
                    color="light"
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    onClick={() => handleClickOpen("paper")}
                    variant="gradient"
                    color="dark"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>check</Icon>
                    &nbsp; Update
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminUsers;
