import * as React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem"; // Soft UI Dashboard React components
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/visitlogs/data/authorsTableData";

import Slide from "@mui/material/Slide";
import {
  getVisits,
  getAdminVisits,
  clearVisit,
} from "redux/actions/userActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VisitLogs() {
  const handleOpenMenu = (event, id, status) => {
    setCurrentID(id);
    setCurrentStatus(status);
    setOpenMenu(event.currentTarget);
  };
  const { columns, rows, id, status } = authorsTableData(handleOpenMenu);
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleCloseMenu = () => setOpenMenu(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [currentCompanyID, setCurrentCompanyID] = React.useState();
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [purpose, setPurpose] = React.useState("");
  const [currentID, setCurrentID] = React.useState();
  const [currentStatus, setCurrentStatus] = React.useState();
  const [showDelete, setShowDelete] = React.useState(false);
  const [showSuspend, setShowSuspend] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);

  const [scroll, setScroll] = React.useState("paper");
  const logtype = useSelector((state) => state.onboardingReducer.type);
  const company_name = useSelector(
    (state) => state.onboardingReducer?.company_name
  );
  const role = useSelector((state) => state.onboardingReducer.profile.type);
  const visits = useSelector((state) => state.userReducer?.visits);
  const companies = useSelector((state) => state.adminReducer?.companies);
  const company_id = useSelector(
    (state) => state.onboardingReducer?.profile?.company_id
  );

  const minicompanies = companies.map((company) => ({
    label: company.name,
    id: company.id,
  }));

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleShowEdit = () => {
    handleCloseMenu();
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    showEdit(false);
  };

  const handleSuspendClose = () => {
    setShowSuspend(false);
  };
  const handleShowDelete = () => {
    handleCloseMenu();
    setShowDelete(true);
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
  };
  const handleShowSuspend = () => {
    handleCloseMenu();
    setShowSuspend(true);
  };
  const handleChangeCompany = (id) => {
    if (id) {
      setCurrentCompanyID(id);
      dispatch(clearVisit());
      dispatch(getAdminVisits(id));
    }
  };
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const company = company_name;
    const title = "SmartVisit Visitors Report";
    const headers = [
      [
        "SN",
        "Name of Visitor",
        "Phone Number",
        "Address",
        "Purpose of Visit",
        "Department",
        "Whom to see ",
        "Time-in",
        "Time-out",
      ],
    ];

    const data = visits.map((visit, index) => [
      index + 1,
      visit.name,
      visit.phone,
      visit.address,
      visit.purpose,
      visit.department,
      visit.visitinguser,
      visit.sign_in_time,
      visit.sign_out_time,
    ]);

    let content = {
      startY: 80,
      head: headers,
      body: data,
    };

    doc.text(company_name, marginLeft, 40);
    doc.text(title, marginLeft, 60);
    doc.autoTable(content);
    doc.save("report.pdf");
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    setCurrentCompanyID(company_id);
    dispatch(clearVisit());
    if (role !== 0) dispatch(getAdminVisits(company_id));
  }, []);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Menu
        id="simple-menu"
        anchorEl={openMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
      >
        {/* {logtype !== "admin" && currentStatus === 0 ? (
          <MenuItem onClick={handleClickOpen("paper")}>Edit</MenuItem>
        ) : (
          <MenuItem onClick={handleClickOpen("paper")}>Activate</MenuItem>
        )} */}
        <MenuItem onClick={handleCopy}>Copy Secret Code</MenuItem>
        <MenuItem onClick={handleShowDelete}>Delete</MenuItem>
      </Menu>

      <Dialog
        open={showDelete}
        onClose={handleCloseDelete}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete User?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to delete this visit, this action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={handleClose} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showSuspend}
        onClose={handleSuspendClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Suspend User?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to suspend this user.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuspendClose}>Cancel</Button>
          <Button onClick={handleSuspendClose} autoFocus>
            Suspend
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Modify Visit</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <MDBox pt={2}>
            <MDBox
              pb={2}
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "55ch" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                required
                id="standard-error-helper-text"
                label="Visitor's Name"
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                required
                id="standard-error-helper-text"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                required
                id="standard-error-helper-text"
                label="Phone"
                onChange={(e) => setPhone(e.target.value)}
              />
              <TextField
                required
                id="standard-error-helper-text"
                label="Address"
                multiline
                onChange={(e) => setAddress(e.target.value)}
                rows={4}
              />
              <TextField
                required
                id="standard-error-helper-text"
                label="Purpose"
                onChange={(e) => setPurpose(e.target.value)}
              />
            </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Submit</Button>
        </DialogActions>
      </Dialog>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {!showEdit ? (
            <>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      All Visit Logs in Company
                    </MDTypography>
                  </MDBox>

                  <MDBox pt={3}>
                    <MDBox
                      pb={2}
                      pr={2}
                      pl={4}
                      mb={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        ["@media screen and (min-width: 600px)"]: {
                          flexDirection: "row",
                          display: "flex",
                        },
                      }}
                    >
                      {logtype === "admin" && role === 0 && (
                        <MDBox mb={2} fullWidth>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={minicompanies}
                            onChange={(event, newValue) => {
                              if (newValue?.id)
                                handleChangeCompany(newValue?.id);
                            }}
                            sx={{
                              width: "unset",
                              ["@media screen and (min-width: 600px)"]: {
                                width: 300,
                              },
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Company" />
                            )}
                          />
                        </MDBox>
                      )}

                      {/* <MDBox>
                        <MDInput
                          type="date"
                          label="Date from"
                          value="2018-11-23"
                        />
                        <MDInput
                          sx={{
                            marginRight: 1,
                          }}
                          type="date"
                          label="Date to"
                          value="2018-11-23"
                        />
                      </MDBox> */}
                    </MDBox>
                    {visits.length > 0 ? (
                      role === 3 || role === 0 ? (
                        <Button
                          style={{
                            width: "20%",
                            alignSelf: "center",
                            margin: 10,
                          }}
                          onClick={() => exportPDF()}
                        >
                          Download Reports
                        </Button>
                      ) : null
                    ) : null}
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Edit User
                  </MDTypography>
                </MDBox>
                <MDBox pt={2}>
                  <MDBox
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { ml: 4, mt: 2, width: "25ch" },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <TextField
                        required
                        id="standard-error-helper-text"
                        label="Company Name"
                        defaultValue="Hello World"
                        variant="standard"
                      />
                      <TextField
                        required
                        id="standard-error-helper-text"
                        label="Email"
                        defaultValue="Hello World"
                        variant="standard"
                      />
                    </div>
                    <div>
                      <TextField
                        required
                        id="standard-error-helper-text"
                        label="Phone"
                        defaultValue="Hello World"
                        variant="standard"
                      />
                      <TextField
                        required
                        id="standard-error-helper-text"
                        label="Address"
                        multiline
                        rows={4}
                        defaultValue="Hello World"
                        variant="standard"
                      />
                    </div>

                    <div>
                      <TextField
                        required
                        id="outlined-required"
                        label="Company Code"
                        defaultValue="Hello World"
                        variant="standard"
                      />
                      <TextField
                        id="outlined-disabled"
                        label="Company Logo"
                        defaultValue="Hello World"
                        variant="standard"
                      />
                    </div>
                  </MDBox>
                </MDBox>
                <MDBox
                  pb={2}
                  pt={2}
                  pr={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MDButton
                    onClick={handleCloseEdit}
                    variant="gradient"
                    sx={{ marginRight: 1 }}
                    color="light"
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    onClick={handleClickOpen("paper")}
                    variant="gradient"
                    color="dark"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>check</Icon>
                    &nbsp; Update
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VisitLogs;
