import PaymentReducer from "./PaymentReducer";
import onboardingReducer from "./onboardingReducer.js";
import userReducer from "./userReducer.js";
import uiReducer from "./uiReducer.js";
import adminReducer from "./adminReducer.js";

import { combineReducers } from "redux";

const allReducers = combineReducers({
  PaymentReducer: PaymentReducer,
  onboardingReducer: onboardingReducer,
  userReducer: userReducer,
  uiReducer: uiReducer,
  adminReducer: adminReducer,
});

export default allReducers;
