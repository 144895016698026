export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBLUvxqLvkCLyOJFcV0HrEae5nEZblF6Uo",
  authDomain: "vendors-7af5c.firebaseapp.com",
  databaseURL: "https://vendors-7af5c.firebaseio.com",
  projectId: "vendors-7af5c",
  storageBucket: "vendors-7af5c.appspot.com",
  messagingSenderId: "2Vendors",
  appId: "1:608453922039:android:b094c13420aed5dab7d7f2",
  measurementId: "G-measurement-id",
};
//const baseUrl = "http://localhost:5000";
const baseUrl = "https://smartvisitapi.7thtower.com";
let parentNav = null;

export const SERVER_DETAILS = {
  getcoy: baseUrl + "/api/auth/coy",
  login: baseUrl + "/api/login",
  getstats: baseUrl + "/api/dashboard",
  adminlogin: baseUrl + "/api/admin/login",
  signup: baseUrl + "/api/users",
  addadmin: baseUrl + "/api/users/admin",
  resetpassword: baseUrl + "/api/resetpassword",

  categories: baseUrl + "/api/categories",
  acceptrightoffer: baseUrl + "/api/acceptrightoffer",

  profile: baseUrl + "/api/profile",
  changepassword: baseUrl + "/api/updatepassword",
  getvisits: baseUrl + "/api/visits",
  getadminvisits: baseUrl + "/api/visits/admin",
  getcompanies: baseUrl + "/api/companies",
  deletecompany: baseUrl + "/api/companies",
  addcompany: baseUrl + "/api/companies",
  updatebilling: baseUrl + "/api/companies/billing",
  suspendcompany: baseUrl + "/api/companies/changestatus",
  suspenduser: baseUrl + "/api/users/changestatus",
  deleteuser: baseUrl + "/api/users",
  getmessages: baseUrl + "/api/messages",
  openmessage: baseUrl + "/api/messages",
  deletemessage: baseUrl + "/api/messages",
  getnotifications: baseUrl + "/api/notifications",
  opennotifications: baseUrl + "/api/notifications",
  deletenotifications: baseUrl + "/api/notifications",

  getusers: baseUrl + "/api/usersof",
  getadminusers: baseUrl + "/api/adminusersof",
  getcompanyid: baseUrl + "/api/getcompanyid",

  addvisit: baseUrl + "/api/visits",
  confirmvisit: baseUrl + "/api/visits/confirm",
  approvevisit: baseUrl + "/api/visits/approve",
  declinevisit: baseUrl + "/api/visits/decline",
  checkoutvisit: baseUrl + "/api/visits/checkout",

  contactsupport: baseUrl + "/api/support",
  activateemail: baseUrl + "/api/activateemail",
};
