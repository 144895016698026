import * as types from "../actions/ActionTypes";
const loadingReducer = (state = { loading: false, error: null }, action) => {
  switch (action.type) {
    case types.ACTION_START:
      return Object.assign({}, state, {
        error: null,
        loading: true,
        success: null,
      });

    case types.ACTION_FAILED:
      return Object.assign({}, state, {
        error: action.payload,
        loading: false,
        success: null,
      });
    case types.ACTION_CLEAR:
      return Object.assign({}, state, {
        error: null,
        loading: false,
        success: null,
      });

    case types.ACTION_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        loading: false,
        success: action.payload,
      });

    default:
      return state;
  }
};
export default loadingReducer;
