import Dashboard from "layouts/dashboard";

import Messages from "layouts/messages";

import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password";

// @mui icons
import Icon from "@mui/material/Icon";
import Companies from "layouts/companies";
import Users from "layouts/users";
import AdminUsers from "layouts/adminusers";
import VisitLogs from "layouts/visitlogs";
import Support from "layouts/support";
import AdminLogin from "layouts/authentication/admin-login";
import Home from "layouts/home";
import FAQ from "layouts/home/faq";
import Contact from "layouts/home/contact";
import Documentation from "layouts/home/documentation";
import About from "layouts/home/about";
import DataProtection from "layouts/home/data-protection";
import Activate from "layouts/authentication/activate";
import InviteGuest from "layouts/inviteguest";
import VerifyGuest from "layouts/verifyguest";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    type: "collapse",
    name: "Invite Guest",
    key: "invite-guest",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/invite-guest",
    component: <InviteGuest />,
    isPrivate: true,
    isAdmin: false,
    AdminLevel: 1,
  },

  {
    type: "collapse",
    name: "Verify Guest",
    key: "verify-guest",
    icon: <Icon fontSize="small">check</Icon>,
    route: "/verify-guest",
    component: <VerifyGuest />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    type: "collapse",
    name: "Companies",
    key: "companies",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/companies",
    component: <Companies />,
    isPrivate: true,
    isAdmin: true,
  },

  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    component: <Users />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    type: "collapse",
    name: "Visit Logs",
    key: "visit-logs",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/visit-logs",
    component: <VisitLogs />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    icon: <Icon fontSize="small">mail</Icon>,
    route: "/messages",
    component: <Messages />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    type: "none",
    name: "Login",
    key: "login",
    route: "/login",
    component: <SignIn />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Home",
    key: "index",
    route: "/",
    component: <Home />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Faq",
    key: "faq",
    route: "/faq",
    component: <FAQ />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "About",
    key: "about",
    route: "/about",
    component: <About />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Dataprotction",
    key: "data-protection",
    route: "/data-protection",
    component: <DataProtection />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Documentation",
    key: "documentation",
    route: "/documentation",
    component: <Documentation />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Contact",
    key: "contact",
    route: "/contact",
    component: <Contact />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Signup",
    key: "signup",
    route: "/signup",
    component: <SignUp />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Activate",
    key: "activate",
    route: "/activate/:id",
    component: <Activate />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Resetpassword",
    key: "resetpassword",
    route: "/resetpassword",
    component: <ResetPassword />,
    isPrivate: false,
  },
  {
    type: "none",
    name: "Admin Login",
    key: "adminlogin",
    route: "/admin/login",
    component: <AdminLogin />,
    isPrivate: false,
  },

  {
    type: "collapse",
    name: "Admin Users",
    key: "admin-users",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/admin-users",
    component: <AdminUsers />,
    isPrivate: true,
    isAdmin: true,
  },

  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    isPrivate: true,
    isAdmin: false,
  },

  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <Icon fontSize="small">headset</Icon>,
    route: "/support",
    component: <Support />,
    isPrivate: true,
    isAdmin: true,
  },
];

export default routes;
