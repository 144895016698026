/* eslint-disable react/prop-types */
import * as React from "react";
// Soft UI Dashboard React components
import { useSelector } from "react-redux";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import MDBadge from "components/MDBadge";
import IconButton from "@mui/material/IconButton";
import NotificationItem from "examples/Items/NotificationItem";
import Button from "@mui/material/Button";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
// Images

import team2 from "assets/images/team-2.jpg";

export default function data(handleOpenMenu) {
  const users = useSelector((state) => state.adminReducer?.users);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(
      users?.map((user) => ({
        author: (
          <Author image={team2} name={user.firstname} email={user.email} />
        ),
        function: <Job title={user.phone} description="Type: Normal User" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={
                user.status === 0
                  ? "Pending"
                  : user.status === 1
                  ? "Active"
                  : "Suspended"
              }
              color={
                user.status === 0
                  ? "warning"
                  : user.status === 1
                  ? "success"
                  : "error"
              }
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        employed: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            23/04/18
          </MDTypography>
        ),
        action: (
          <>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={(event) => handleOpenMenu(event, user.id, user.status)}
            >
              <Icon>more_vert</Icon>
            </IconButton>
          </>
        ),
      }))
    );
  }, [users]);

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      {
        Header: "Full Name & Email",
        accessor: "author",
        width: "45%",
        align: "left",
      },
      { Header: "Phone", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Date Added", accessor: "employed", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: data,
  };
}
