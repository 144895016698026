import React from "react";
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Loader from "components/Loader";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  signup,
  clearLoginError,
  setBase,
} from "redux/actions/onboardingAction";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { activateEmail } from "redux/actions/userActions";

function Activate() {
  const loading = useSelector((state) => state.onboardingReducer.loading);
  const error = useSelector((state) => state.onboardingReducer.error);
  const success = useSelector((state) => state.onboardingReducer.success);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [firstname, setFirstname] = React.useState();
  const [lastname, setLastname] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [password, setPassword] = React.useState();
  const [password2, setPassword2] = React.useState();

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    handleClose();
    setAgree((prevAgree) => !prevAgree);
  };
  const handleSignup = () => {
    const user = {
      firstname,
      surname: lastname,
      email,
      phone,
      password,
      password2,
      agree,
      company_id: 1,
    };
    dispatch(signup(user));
  };
  const descriptionElementRef = React.useRef(null);
  const alertContent = (msg) => (
    <MDTypography variant="body2" color="white">
      {msg}
    </MDTypography>
  );
  React.useEffect(() => {
    dispatch(clearLoginError());
    dispatch(setBase("user"));
    const email = Buffer.from(id, "base64").toString("ascii");
    dispatch(activateEmail({ email }));
  }, []);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <CoverLayout image={bgImage}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
              )
              .join("\n")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton onClick={handleAgree}>Agree</MDButton>
        </DialogActions>
      </Dialog>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        ></MDBox>
        <MDBox pt={10} pb={10} px={5}>
          <MDBox component="form" role="form">
            <MDTypography
              variant="h4"
              textAlign="center"
              fontWeight="medium"
              color="black"
              mt={1}
            >
              Congratulations!
            </MDTypography>

            <MDTypography
              display="block"
              textAlign="center"
              variant="button"
              color="black"
              my={1}
            >
              Your email {Buffer.from(id, "base64").toString("ascii")} has been
              successfully confirmed.
            </MDTypography>
            <MDBox mt={2} mb={1}>
              {loading && <Loader />}
              {error && (
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDAlert color="error" dismissible>
                    {alertContent(error)}
                  </MDAlert>
                </MDBox>
              )}
              {success && (
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDAlert color="success" dismissible>
                    {alertContent(success)}
                  </MDAlert>
                </MDBox>
              )}
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                proceed to{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Activate;
