// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { openMessage } from "redux/actions/userActions";

function Transaction({ id, color, icon, name, description, value }) {
  const dispatch = useDispatch();

  const handleOpenMessage = (id) => {
    dispatch(openMessage(id));
  };
  return (
    <MDBox
      key={name}
      component="li"
      onClick={() => handleOpenMessage(id)}
      sx={{ cursor: "pointer" }}
      py={1}
      pr={2}
      mb={1}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDButton variant="outlined" color={color} iconOnly circular>
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="button" fontWeight="medium" gutterBottom>
              {name}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDTypography
          variant="h6"
          sx={{ fontSize: 10 }}
          color={color}
          fontWeight="medium"
          textGradient
        >
          {value}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Transaction;
