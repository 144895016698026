import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const logstatus = useSelector((state) => state.onboardingReducer.logstatus);
  const basetype = useSelector((state) => state.onboardingReducer.basetype);

  return logstatus ? (
    children
  ) : (
    <Navigate replace to={basetype === "admin" ? "/admin/login" : "/login"} />
  );
};

export default PrivateRoute;
