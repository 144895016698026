import React from "react";

function Documentation() {
  return (
    <>
      <a id="skippy" class="sr-only sr-only-focusable" href="#content">
        <div class="container">
          <span class="skiplink-text">Skip to main content</span>
        </div>
      </a>
      <header class="navbar navbar-horizontal navbar-expand navbar-dark flex-row align-items-md-center ct-navbar bg-primary py-2">
        <a
          class="navbar-brand mr-0 mr-md-2"
          href="../../../index-2.html"
          aria-label="Bootstrap"
        >
          <img src="./assets/img/brand/white.png" />
          <sup>DOCS</sup>
        </a>
        <ul class="navbar-nav flex-row mr-auto ml-4 d-none d-md-flex">
          <li class="nav-item">
            <a class="nav-link" href="../../../index-2.html">
              Live Preview
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://github.com/creativetimofficial/ct-argon-design-system-pro/issues"
              rel="nofollow"
              target="_blank"
            >
              Support
            </a>
          </li>
        </ul>
        <div class="d-none d-sm-block ml-auto">
          <ul class="navbar-nav ct-navbar-nav flex-row align-items-center">
            <li class="nav-item">
              <a
                class="nav-link nav-link-icon"
                href="https://www.facebook.com/creativetim"
                rel="nofollow"
                target="_blank"
              >
                <i class="fab fa-facebook-square"></i>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link nav-link-icon"
                href="https://twitter.com/creativetim"
                rel="nofollow"
                target="_blank"
              >
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link nav-link-icon"
                href="https://www.instagram.com/creativetimofficial/"
                rel="nofollow"
                target="_blank"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link nav-link-icon"
                href="https://dribbble.com/creativetim"
                rel="nofollow"
                target="_blank"
              >
                <i class="fab fa-dribbble"></i>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link nav-link-icon"
                href="https://github.com/creativetimofficial"
                rel="nofollow"
                target="_blank"
              >
                <i class="fab fa-github"></i>
              </a>
            </li>
          </ul>
        </div>

        <button
          class="navbar-toggler ct-search-docs-toggle d-block d-md-none ml-auto ml-sm-0"
          type="button"
          data-toggle="collapse"
          data-target="#ct-docs-nav"
          aria-controls="ct-docs-nav"
          aria-expanded="false"
          aria-label="Toggle docs navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </header>
      <div class="container-fluid">
        <div class="row flex-xl-nowrap">
          <div class="col-12 col-md-3 col-xl-2 ct-sidebar">
            <nav class="collapse ct-links" id="ct-docs-nav">
              <div class="ct-toc-item active">
                <a class="ct-toc-link" href="#docs">
                  Getting started
                </a>
                <ul class="nav ct-sidenav">
                  <li class="">
                    <a href="overview.html">Overview</a>
                  </li>
                  <li class="active ct-sidenav-active">
                    <a href="quick-start.html">Quick Start</a>
                  </li>
                  <li class="">
                    <a href="license.html">License</a>
                  </li>
                  <li class="">
                    <a href="contents.html">Contents</a>
                  </li>
                  <li class="">
                    <a href="build-tools.html">Build Tools</a>
                  </li>
                </ul>
              </div>
              <div class="ct-toc-item active">
                <a class="ct-toc-link" href="#docs">
                  Foundation
                </a>
                <ul class="nav ct-sidenav">
                  <li class="">
                    <a href="../foundation/colors.html">Colors</a>
                  </li>
                  <li class="">
                    <a href="../foundation/grid.html">Grid</a>
                  </li>
                  <li class="">
                    <a href="../foundation/typography.html">Typography</a>
                  </li>
                  <li class="">
                    <a href="../foundation/icons.html">Icons</a>
                  </li>
                </ul>
              </div>
              <div class="ct-toc-item active">
                <a class="ct-toc-link" href="#docs">
                  Components
                </a>
                <ul class="nav ct-sidenav">
                  <li class="">
                    <a href="../components/alerts.html">Alerts</a>
                  </li>
                  <li class="">
                    <a href="../components/badge.html">Badge</a>
                  </li>
                  <li class="">
                    <a href="../components/breadcrumb.html">Breadcrumb</a>
                  </li>
                  <li class="">
                    <a href="../components/buttons.html">Buttons</a>
                  </li>
                  <li class="">
                    <a href="../components/card.html">Card</a>
                  </li>
                  <li class="">
                    <a href="../components/carousel.html">Carousel</a>
                  </li>
                  <li class="">
                    <a href="../components/collapse.html">Collapse</a>
                  </li>
                  <li class="">
                    <a href="../components/dropdowns.html">Dropdowns</a>
                  </li>
                  <li class="">
                    <a href="../components/forms.html">Forms</a>
                  </li>
                  <li class="">
                    <a href="../components/images.html">Images</a>
                  </li>
                  <li class="">
                    <a href="../components/info-areas.html">Info Areas</a>
                  </li>
                  <li class="">
                    <a href="../components/modal.html">Modal</a>
                  </li>
                  <li class="">
                    <a href="../components/navs.html">Navs</a>
                  </li>
                  <li class="">
                    <a href="../components/navbar.html">Navbar</a>
                  </li>
                  <li class="">
                    <a href="../components/pagination.html">Pagination</a>
                  </li>
                  <li class="">
                    <a href="../components/popovers.html">Popovers</a>
                  </li>
                  <li class="">
                    <a href="../components/progress.html">Progress</a>
                  </li>
                  <li class="">
                    <a href="../components/spinners.html">Spinners</a>
                  </li>
                  <li class="">
                    <a href="../components/tables.html">Tables</a>
                  </li>
                  <li class="">
                    <a href="../components/toasts.html">Toasts</a>
                  </li>
                  <li class="">
                    <a href="../components/tooltips.html">Tooltips</a>
                  </li>
                </ul>
              </div>
              <div class="ct-toc-item active">
                <a class="ct-toc-link" href="#docs">
                  Plugins
                </a>
                <ul class="nav ct-sidenav">
                  <li class="">
                    <a href="../plugins/bootstrap-switch.html">
                      Bootstrap Switch
                    </a>
                  </li>
                  <li class="">
                    <a href="../plugins/sliders.html">Sliders</a>
                  </li>
                  <li class="">
                    <a href="../plugins/glide-carousel.html">Glide Carousel</a>
                  </li>
                  <li class="">
                    <a href="../plugins/datetimepicker.html">DateTimePicker</a>
                  </li>
                  <li class="">
                    <a href="../plugins/select.html">Select</a>
                  </li>
                  <li class="">
                    <a href="../plugins/fileupload-jasny.html">
                      FileUpload Jasny
                    </a>
                  </li>
                  <li class="">
                    <a href="../plugins/headroom.html">Headroom</a>
                  </li>
                  <li class="">
                    <a href="../plugins/perfect-scrollbar.html">
                      Perfect Scrollbar
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div class="d-none d-xl-block col-xl-2 ct-toc">
            <ul class="section-nav">
              <li class="toc-entry toc-h2">
                <a href="#css">CSS</a>
              </li>
              <li class="toc-entry toc-h2">
                <a href="#js">JS</a>
              </li>
              <li class="toc-entry toc-h2">
                <a href="#starter-template">Starter template</a>
              </li>
              <li class="toc-entry toc-h2">
                <a href="#important-globals">Important globals</a>
                <ul>
                  <li class="toc-entry toc-h3">
                    <a href="#html5-doctype">HTML5 doctype</a>
                  </li>
                  <li class="toc-entry toc-h3">
                    <a href="#responsive-meta-tag">Responsive meta tag</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <main
            class="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 ct-content"
            role="main"
          >
            <div class="ct-page-title">
              <h1 class="ct-title" id="content">
                Quick start
              </h1>
            </div>
            <p class="ct-lead">
              To start using this design system you will need to import some
              files in your current project or start from scratch using our
              template (scroll down in this page to view it).
            </p>
            <hr />
            <h2 id="css">CSS</h2>
            <p>
              Copy-paste the stylesheet{" "}
              <code class="highlighter-rouge">&lt;link&gt;</code> into your{" "}
              <code class="highlighter-rouge">&lt;head&gt;</code> before all
              other stylesheets to load our CSS.
            </p>
            <figure class="highlight">
              <pre>
                <code class="language-html" data-lang="html">
                  <span class="c">&lt;!-- Fonts --&gt;</span>
                  <span class="nt">&lt;link</span> <span class="na">href=</span>
                  <span class="s">
                    "https://fonts.googleapis.com/css?family="
                  </span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"stylesheet"</span>
                  <span class="nt">&gt;</span>
                  <span class="c">&lt;!-- Icons --&gt;</span>
                  <span class="nt">&lt;link</span> <span class="na">href=</span>
                  <span class="s">
                    "/assets/vendor/nucleo/css/nucleo.css"
                  </span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"stylesheet"</span>
                  <span class="nt">&gt;</span>
                  <span class="nt">&lt;link</span> <span class="na">href=</span>
                  <span class="s">
                    "/assets/vendor/font-awesome/css/font-awesome.min.css"
                  </span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"stylesheet"</span>
                  <span class="nt">&gt;</span>
                  <span class="c">&lt;!-- Theme CSS --&gt;</span>
                  <span class="nt">&lt;link</span> <span class="na">type=</span>
                  <span class="s">"text/css"</span>{" "}
                  <span class="na">href=</span>
                  <span class="s">
                    "/assets/css/argon-design-system.css"
                  </span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"stylesheet"</span>
                  <span class="nt">&gt;</span>
                </code>
              </pre>
            </figure>
            <h2 id="js">JS</h2>
            <p>
              Many of our components require the use of JavaScript to function.
              Specifically, they require{" "}
              <a href="https://jquery.com/" rel="nofollow">
                jQuery
              </a>
              ,{" "}
              <a href="https://popper.js.org/" rel="nofollow">
                Popper.js
              </a>
              , and our own JavaScript plugins. Place the following{" "}
              <code class="highlighter-rouge">&lt;script&gt;</code>s near the
              end of your pages, right before the closing{" "}
              <code class="highlighter-rouge">&lt;/body&gt;</code> tag, to
              enable them. jQuery must come first, then Popper.js, and then our
              JavaScript plugins.
            </p>
            <p>
              We use{" "}
              <a
                href="https://blog.jquery.com/2016/06/09/jquery-3-0-final-released/"
                rel="nofollow"
              >
                jQuery’s slim build
              </a>
              , but the full version is also supported.
            </p>
            <figure class="highlight">
              <pre>
                <code class="language-html" data-lang="html">
                  <span class="c">&lt;!-- Core --&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">"/assets/vendor/jquery/jquery.min.js"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">"/assets/vendor/popper/popper.min.js"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "/assets/vendor/bootstrap/bootstrap.min.js"
                  </span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">&lt;!-- Theme JS --&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/argon-design-system.js?v=1.0.0"
                  </span>{" "}
                  <span class="na">type=</span>
                  <span class="s">"text/javascript"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                </code>
              </pre>
            </figure>
            <p>
              Need to use a certain plugin in your page? You can find out how to
              integrate them and make them work in the{" "}
              <a href="../../../../docs/plugins/datepicker.html">Plugins</a>{" "}
              dedicated page. In this way you will be sure that your website is
              optimized and uses only the needed resources.
            </p>
            <h2 id="starter-template">Starter template</h2>
            <p>
              Be sure to have your pages set up with the latest design and
              development standards. That means using an HTML5 doctype and
              including a viewport meta tag for proper responsive behaviors. Put
              it all together and your pages should look like this:
            </p>
            <figure class="highlight">
              <pre>
                <code class="language-html" data-lang="html">
                  <span class="cp">&lt;!DOCTYPE html&gt;</span>
                  <span class="nt">&lt;html&gt;</span>
                  <span class="nt">&lt;head&gt;</span>
                  <span class="nt">&lt;meta</span>{" "}
                  <span class="na">charset=</span>
                  <span class="s">"utf-8"</span>
                  <span class="nt">&gt;</span>
                  <span class="nt">&lt;meta</span> <span class="na">name=</span>
                  <span class="s">"viewport"</span>{" "}
                  <span class="na">content=</span>
                  <span class="s">
                    "width=device-width, initial-scale=1, shrink-to-fit=no"
                  </span>
                  <span class="nt">&gt;</span>
                  <span class="nt">&lt;title&gt;</span>Hello World!
                  <span class="nt">&lt;/title&gt;</span>
                  <span class="c">&lt;!-- Favicon --&gt;</span>
                  <span class="nt">&lt;link</span> <span class="na">href=</span>
                  <span class="s">"/assets/img/brand/favicon.png"</span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"icon"</span> <span class="na">type=</span>
                  <span class="s">"image/png"</span>
                  <span class="nt">&gt;</span>
                  <span class="c">&lt;!-- Fonts --&gt;</span>
                  <span class="nt">&lt;link</span> <span class="na">href=</span>
                  <span class="s">
                    "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
                  </span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"stylesheet"</span>
                  <span class="nt">&gt;</span>
                  <span class="c">&lt;!-- Icons --&gt;</span>
                  <span class="nt">&lt;link</span> <span class="na">href=</span>
                  <span class="s">
                    "/assets/vendor/nucleo/css/nucleo.css"
                  </span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"stylesheet"</span>
                  <span class="nt">&gt;</span>
                  <span class="nt">&lt;link</span> <span class="na">href=</span>
                  <span class="s">
                    "/assets/vendor/font-awesome/css/font-awesome.min.css"
                  </span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"stylesheet"</span>
                  <span class="nt">&gt;</span>
                  <span class="c">
                    &lt;!-- Argon Design System PRO CSS --&gt;
                  </span>
                  <span class="nt">&lt;link</span> <span class="na">type=</span>
                  <span class="s">"text/css"</span>{" "}
                  <span class="na">href=</span>
                  <span class="s">
                    "/assets/css/argon-design-system.css"
                  </span>{" "}
                  <span class="na">rel=</span>
                  <span class="s">"stylesheet"</span>
                  <span class="nt">&gt;</span>
                  <span class="nt">&lt;/head&gt;</span>
                  <span class="nt">&lt;body&gt;</span>
                  <span class="nt">&lt;h1&gt;</span>Hello, world!
                  <span class="nt">&lt;/h1&gt;</span>
                  <span class="c">&lt;!-- Core JS Files --&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">"./assets/js/core/jquery.min.js"</span>{" "}
                  <span class="na">type=</span>
                  <span class="s">"text/javascript"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">"./assets/js/core/popper.min.js"</span>{" "}
                  <span class="na">type=</span>
                  <span class="s">"text/javascript"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/core/bootstrap.min.js"
                  </span>{" "}
                  <span class="na">type=</span>
                  <span class="s">"text/javascript"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/plugins/perfect-scrollbar.jquery.min.js"
                  </span>{" "}
                  <span class="na">type=</span>
                  <span class="s">"text/javascript"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">"./assets/js/plugins/moment.min.js"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for Switches, full documentation here:
                    http://www.jque.re/plugins/version3/bootstrap.switch/ --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/plugins/bootstrap-switch.js"
                  </span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for the Sliders, full documentation here:
                    http://refreshless.com/nouislider/ --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">"./assets/js/plugins/nouislider.min.js"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for the Carousel, full documentation here:
                    http://jedrzejchalubek.com/ --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">"./assets/js/plugins/glide.js"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for Select, full documentation here:
                    https://joshuajohnson.co.uk/Choices/ --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/plugins/choices.min.js"
                  </span>{" "}
                  <span class="na">type=</span>
                  <span class="s">"text/javascript"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for Switches, full documentation here:
                    http://www.jque.re/plugins/version3/bootstrap.switch/ --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/plugins/bootstrap-switch.js"
                  </span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for the DateTimePicker, full documentation
                    here: https://flatpickr.js.org/ --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/plugins/bootstrap-datetimepicker.js"
                  </span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for Tags, full documentation here:
                    https://github.com/bootstrap-tagsinput/bootstrap-tagsinputs
                    --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/plugins/bootstrap-tagsinput.js"
                  </span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for Fileupload, full documentation here:
                    http://www.jasny.net/bootstrap/javascript/#fileinput --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/plugins/jasny-bootstrap.min.js"
                  </span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Plugin for Headrom, full documentation here:
                    https://wicky.nillia.ms/headroom.js/ --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">"./assets/js/plugins/headroom.min.js"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">&lt;!-- Google Maps Plugin --&gt;</span>
                  <span class="nt">&lt;script </span>
                  <span class="na">type=</span>
                  <span class="s">"text/javascript"</span>{" "}
                  <span class="na">src=</span>
                  <span class="s">
                    "https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
                  </span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="c">
                    &lt;!-- Control Center for Argon Design System: parallax
                    effects, scripts for the example pages etc --&gt;
                  </span>
                  <span class="nt">&lt;script </span>
                  <span class="na">src=</span>
                  <span class="s">
                    "./assets/js/argon-design-system.js?v=1.0.0"
                  </span>{" "}
                  <span class="na">type=</span>
                  <span class="s">"text/javascript"</span>
                  <span class="nt">&gt;&lt;/script&gt;</span>
                  <span class="nt">&lt;/body&gt;</span>
                  <span class="nt">&lt;/html&gt;</span>
                </code>
              </pre>
            </figure>
            <h2 id="important-globals">Important globals</h2>
            <p>
              Argon Design System PRO employs a handful of important global
              styles and settings that you’ll need to be aware of when using it,
              all of which are almost exclusively geared towards the{" "}
              <em>normalization</em> of cross browser styles. Let’s dive in.
            </p>
            <h3 id="html5-doctype">HTML5 doctype</h3>
            <p>
              Bootstrap requires the use of the HTML5 doctype. Without it,
              you’ll see some funky incomplete styling, but including it
              shouldn’t cause any considerable hiccups.
            </p>
            <figure class="highlight">
              <pre>
                <code class="language-html" data-lang="html">
                  <span class="cp">&lt;!doctype html&gt;</span>
                  <span class="nt">&lt;html</span> <span class="na">lang=</span>
                  <span class="s">"en"</span>
                  <span class="nt">&gt;</span>
                  ...
                  <span class="nt">&lt;/html&gt;</span>
                </code>
              </pre>
            </figure>
            <h3 id="responsive-meta-tag">Responsive meta tag</h3>
            <p>
              Bootstrap is developed <em>mobile first</em>, a strategy in which
              we optimize code for mobile devices first and then scale up
              components as necessary using CSS media queries. To ensure proper
              rendering and touch zooming for all devices,{" "}
              <strong>add the responsive viewport meta tag</strong> to your{" "}
              <code class="highlighter-rouge">&lt;head&gt;</code>.
            </p>
            <figure class="highlight">
              <pre>
                <code class="language-html" data-lang="html">
                  <span class="nt">&lt;meta</span> <span class="na">name=</span>
                  <span class="s">"viewport"</span>{" "}
                  <span class="na">content=</span>
                  <span class="s">
                    "width=device-width, initial-scale=1, shrink-to-fit=no"
                  </span>
                  <span class="nt">&gt;</span>
                </code>
              </pre>
            </figure>
            <p>
              You can see an example of this in action in the{" "}
              <a href="#starter-template">starter template</a>.
            </p>
          </main>
        </div>
      </div>
    </>
  );
}

export default Documentation;
