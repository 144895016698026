import axios from "axios";
import * as config from "../../config/config";
import * as types from "./ActionTypes";
// import { showAlert } from '../Helpers/navigationHelper';

export const addVisit = (user) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ADD_VISIT_START });
    axios
      .post(config.SERVER_DETAILS.addvisit, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.ADD_VISIT_SUCCESS,
          payload: response.data,
        });
        dispatch({ type: types.ADD_VISIT_END });
        //  dispatch(getInstallments({ email: response.data.email }));
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ADD_VISIT_END,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const activateEmail = (user) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };
    dispatch({ type: types.ACTION_START });
    axios
      .put(config.SERVER_DETAILS.activateemail, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Email Activated Successfully",
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const contactSupport = (message) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .post(config.SERVER_DETAILS.contactsupport, JSON.stringify(message), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Message Sent Successfully",
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const clearVisit = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_VISIT_SUCCESS,
    });
  };
};

export const getVisits = () => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .get(config.SERVER_DETAILS.getvisits, {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.GET_VISITS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const getAdminVisits = (id) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .get(config.SERVER_DETAILS.getadminvisits + "/" + id, {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.GET_VISITS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const clearMessage = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_MESSAGE_SUCCESS,
    });
  };
};

export const openMessage = (msgID) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .get(config.SERVER_DETAILS.openmessage + `/${msgID}`, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: types.OPEN_MESSAGE_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const deleteMessage = (msgID) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .delete(config.SERVER_DETAILS.deletemessage + `/${msgID}`, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: types.DELETE_MESSAGE_SUCCESS,
          payload: response?.data,
        });
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "This message has been deleted successfully",
        });
        dispatch(clearMessage());
        dispatch(getMessages());
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const getMessages = (companyID) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .get(config.SERVER_DETAILS.getmessages, {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.GET_MESSAGES_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};
