import axios from "axios";
import jwt_decode from "jwt-decode";
import * as config from "../../config/config";
import * as types from "./ActionTypes";
// import { showAlert } from '../Helpers/navigationHelper';
export const opennav = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.OPEN_NAV });
  };
};

export const closenav = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.CLOSE_NAV });
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.LOGOUT_SUCCESS });
  };
};

export const setBase = (base) => {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_BASE_SUCCESS, payload: base });
  };
};

export const clearLoginError = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.CLEAR_LOGIN });
  };
};

export const adminlogin = (user, navigate) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };
    dispatch({ type: types.ADMIN_LOGIN_START });
    axios
      .post(config.SERVER_DETAILS.adminlogin, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        const token = response.data.token;
        const decoded = jwt_decode(token);
        const payload = {
          token: response.data.token,
          email: decoded.email,
          displayname: decoded.firstname,
          company_name: decoded.company_name,
          type: "admin",
        };
        dispatch({ type: types.ADMIN_LOGIN_SUCCESS, payload });
        dispatch({ type: types.ADMIN_LOGIN_END });
        dispatch(() => navigate("/dashboard"));
      })
      .catch((error) => {
        dispatch({
          type: types.ADMIN_LOGIN_END,
          payload: error?.response?.data
            ? error.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const login = (user, navigate) => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
    };
    dispatch({ type: types.LOGIN_START });
    axios
      .post(config.SERVER_DETAILS.login, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        const token = response.data.token;
        const decoded = jwt_decode(token);
        const payload = {
          token: response.data.token,
          email: decoded.email,
          displayname: decoded.firstname,
          type: "user",
        };
        dispatch({ type: types.LOGIN_SUCCESS, payload });
        dispatch(() => navigate("/dashboard"));
      })
      .catch((error) => {
        dispatch({
          type: types.LOGIN_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const getCompanyID = (url) => {
  return (dispatch, getState) => {
    dispatch({ type: types.GET_COMPANYID_START });
    axios
      .get(config.SERVER_DETAILS.getcompanyid + "/" + url)
      .then((response) => {
        dispatch({
          type: types.GET_COMPANYID_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.GET_COMPANYID_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const getStats = () => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .get(config.SERVER_DETAILS.getstats, {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.GET_STATS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const updateProfile = (user) => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .put(config.SERVER_DETAILS.profile, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.UPDATE_PROFILE_SUCCESS,
          payload: response?.data,
        });
        dispatch({
          type: types.ACTION_SUCCESS,
          payload: "Profile updated successfully",
        });
      })

      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const getProfile = () => {
  return (dispatch, getState) => {
    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.ACTION_START });
    axios
      .get(config.SERVER_DETAILS.profile, {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.GET_PROFILE_SUCCESS,
          payload: response?.data,
        });
        dispatch({
          type: types.ACTION_CLEAR,
        });
      })

      .catch((error) => {
        // setShow(true);
        dispatch({
          type: types.ACTION_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const signup = (user) => {
  return (dispatch, getState) => {
    if (
      !user.email ||
      !user.phone ||
      !user.firstname ||
      !user.surname ||
      !user.password
    ) {
      dispatch({
        type: types.SIGN_UP_FAILED,
        payload: "Important field is left blank",
      });
      return;
    } else if (user.password !== user.password2) {
      dispatch({
        type: types.SIGN_UP_FAILED,
        payload: "Password mis-matched!",
      });
      return;
    } else if (!user.agree) {
      dispatch({
        type: types.SIGN_UP_FAILED,
        payload: "Please agree to terms and conditions!",
      });
      return;
    }

    const headers = {
      "Content-Type": "application/json",
    };

    dispatch({ type: types.SIGN_UP_START });
    axios
      .post(config.SERVER_DETAILS.signup, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({ type: types.SIGN_UP_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: types.SIGN_UP_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const changepassword = (user) => {
  return (dispatch, getState) => {
    if (!user.oldpassword || !user.newpassword) {
      dispatch({
        type: types.CHANGE_PASSWORD_FAILED,
        payload: "Important field is left blank",
      });
      return;
    }
    if (user.newpassword !== user.newpassword2) {
      dispatch({
        type: types.CHANGE_PASSWORD_FAILED,
        payload: "New Password mis-matched",
      });
      return;
    }

    const token = getState().onboardingReducer.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    dispatch({ type: types.CHANGE_PASSWORD_START });
    axios
      .put(config.SERVER_DETAILS.changepassword, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: types.CHANGE_PASSWORD_SUCCESS,
          payload: response.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CHANGE_PASSWORD_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};

export const resetpassword = (user) => {
  return (dispatch, getState) => {
    if (!user.email) {
      dispatch({
        type: types.RESET_FAILED,
        payload: "Important field is left blank",
      });
      return;
    }

    const headers = {
      "Content-Type": "application/json",
    };

    dispatch({ type: types.RESET_START });
    axios
      .post(config.SERVER_DETAILS.resetpassword, JSON.stringify(user), {
        headers: headers,
      })
      .then((response) => {
        dispatch({ type: types.RESET_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: types.RESET_FAILED,
          payload: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        });
      });
  };
};
