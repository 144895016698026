import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";

function DataProtection() {
  return (
    <>
      <Header />
      <div class="col-md-8 ml-auto mr-auto">
        <h6 class="category"> </h6>
        <h3 class="title">Our Data Policy</h3>
        <p>
          This is the paragraph where you can write more details about your
          product. Keep you user engaged by providing meaningful information.
          Remember that by this time, the user is curious, otherwise he wouldn't
          scroll to get here. Add a button if you want the user to see more. We
          are here to make life better.
          <br /> <br />
          And now I look and look around and there’s so many Kanyes I've been
          trying to figure out the bed design for the master bedroom at our
          Hidden Hills compound... and thank you for turning my personal jean
          jacket into a couture piece.
        </p>
        <br /> <br />
        <p class="blockquote">
          “And thank you for turning my personal jean jacket into a couture
          piece.”
          <br />
          <br />
          <small>Kanye West, Producer.</small>
        </p>
        <h3 class="title">Using Video Games To Generate Clicks</h3>
        <br />
        <p>
          This is the paragraph where you can write more details about your
          product. Keep you user engaged by providing meaningful information.
          Remember that by this time, the user is curious, otherwise he wouldn't
          scroll to get here. Add a button if you want the user to see more. We
          are here to make life better.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default DataProtection;
