import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MessageInformation from "layouts/messages/components/MessageInformation";
import Transactions from "layouts/messages/components/Transactions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessages } from "redux/actions/userActions";
import { clearMessage } from "redux/actions/userActions";

function Billing() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clearMessage());
    dispatch(getMessages());
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Transactions />
            </Grid>
            <Grid item xs={12} md={8}>
              <MessageInformation />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
