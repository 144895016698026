// react-routers components
import { Link } from "react-router-dom";
import React from "react";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Loader from "components/Loader";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Slide from "@mui/material/Slide";
//import { getVisits } from "redux/actions/userActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "redux/actions/onboardingAction";

function ProfileInfoCard({ title, description, info, action, shadow }) {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.onboardingReducer?.profile?.type);
  const labels = [];
  const values = [];
  const [open, setOpen] = React.useState(false);
  const [firstname, setFirstName] = React.useState(info.firstname);
  const [surname, setSurname] = React.useState(info.surname);
  const [email, setEmail] = React.useState(info.email);
  const [phone, setPhone] = React.useState(info.phone);
  const [department, setDepartment] = React.useState(info.department);
  const [address, setAddress] = React.useState(info.address);
  const [scroll, setScroll] = React.useState("paper");

  const { socialMediaColors } = colors;
  const { size } = typography;

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdate = () => {
    handleClose();
    const user = {
      firstname,
      surname,
      email,
      phone,
      department,
      address,
    };
    dispatch(updateProfile(user));
  };
  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(
        uppercaseLetter,
        ` ${uppercaseLetter.toLowerCase()}`
      );

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"sm"}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edit Profile</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <MDBox pr={1}>
            <MDBox
              pb={2}
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <MDInput
                required
                id="standard-error-helper-text"
                fullWidth
                label="First Name"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <MDInput
                required
                fullWidth
                id="standard-error-helper-text"
                label="Last Name"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
              <MDInput
                required
                id="standard-error-helper-text"
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <MDInput
                required
                id="standard-error-helper-text"
                fullWidth
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <MDInput
                required
                id="standard-error-helper-text"
                label="Department"
                fullWidth
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                rows={4}
              />
              <MDInput
                required
                id="standard-error-helper-text"
                label="Address"
                fullWidth
                multiline
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                rows={4}
              />
            </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {role !== 2 && <Button onClick={handleUpdate}>Update</Button>}
        </DialogActions>
      </Dialog>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
        <MDTypography
          component={Button}
          onClick={() => handleClickOpen("paper")}
          //  onClick={() => }
          color="secondary"
        >
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {renderItems}
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              &nbsp;
            </MDTypography>
            <MDBox></MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
