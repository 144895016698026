import Card from "@mui/material/Card";
import moment from "moment";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Transaction from "layouts/notifications/components/Transaction";

function Transactions() {
  const messages = useSelector((state) => state.userReducer?.messages);
  const newest = messages?.filter(
    (message) => moment().diff(message.createdAt, "days") <= 2
  );
  const others = messages?.filter(
    (message) => moment().diff(message.createdAt, "days") > 2
  );

  const dateToFromNowDaily = (myDate) => {
    var fromNow = moment(myDate).fromNow();

    return moment(myDate).calendar(null, {
      lastWeek: "[Last] dddd",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={2}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Your Notifications
        </MDTypography>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        {newest?.length > 0 && (
          <>
            <MDBox mb={2}>
              <MDTypography
                variant="caption"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                newest
              </MDTypography>
            </MDBox>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {newest?.map((message) => (
                <Transaction
                  color="info"
                  icon="message"
                  id={message.id}
                  name={message.subject}
                  description={message.message}
                  value={dateToFromNowDaily(message.createdAt)}
                />
              ))}
            </MDBox>
          </>
        )}
        {others?.length > 0 && (
          <>
            <MDBox mt={1} mb={2}>
              <MDTypography
                variant="caption"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Past
              </MDTypography>
            </MDBox>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {others?.map((message) => (
                <Transaction
                  color="info"
                  icon="message"
                  id={message.id}
                  name={message.subject}
                  description={message.message}
                  value={dateToFromNowDaily(message.createdAt)}
                />
              ))}
            </MDBox>
          </>
        )}
      </MDBox>
    </Card>
  );
}

export default Transactions;
