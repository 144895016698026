// prop-types is a library for typechecking of props
import React from "react";
import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMaterialUIController } from "context";
import { useDispatch } from "react-redux";
import { deleteMessage } from "redux/actions/userActions";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Message({ id, name, message, companyname, email, phone, noGutter }) {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [showDelete, setShowDelete] = React.useState(false);

  const handleDelete = (id) => {
    dispatch(deleteMessage(id));
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleShowDelete = () => {
    setShowDelete(true);
  };
  return (
    <>
      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={noGutter ? 0 : 1}
        mt={2}
      >
        <Dialog
          open={showDelete}
          onClose={handleCloseDelete}
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Message?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm that you want to delete this message, this action
              cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete}>Cancel</Button>
            <Button onClick={() => handleDelete(id)} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <MDBox width="100%" display="flex" flexDirection="column">
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Company Name:&nbsp;&nbsp;&nbsp;
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {companyname}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Sender Name:&nbsp;&nbsp;&nbsp;
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {name}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Email Address:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {email}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDTypography variant="caption" color="text">
            Phone Number:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {phone}
            </MDTypography>
          </MDTypography>

          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
            mt={4}
          >
            <MDTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {message}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        mb={2}
      >
        <MDTypography
          variant="button"
          fontWeight="medium"
          textTransform="capitalize"
        ></MDTypography>

        <MDBox
          display="flex"
          alignItems="center"
          mt={{ xs: 2, sm: 0 }}
          ml={{ xs: -1.5, sm: 0 }}
        >
          <MDBox mr={1}>
            <MDButton
              onClick={() => alert("Reply")}
              variant="text"
              color={darkMode ? "white" : "dark"}
            >
              <Icon>reply</Icon>&nbsp;Reply
            </MDButton>
          </MDBox>
          <MDButton onClick={handleShowDelete} variant="text" color="error">
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default values for the props of Bill
Message.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Message.propTypes = {
  name: PropTypes.string.isRequired,
  companyname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Message;
