import React from "react";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useDispatch, useSelector } from "react-redux";
import {
  resetpassword,
  clearLoginError,
  setBase,
} from "redux/actions/onboardingAction";

function Cover() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.onboardingReducer.loading);
  const error = useSelector((state) => state.onboardingReducer.error);
  const success = useSelector((state) => state.onboardingReducer.success);

  const [email, setEmail] = React.useState();

  const handleReset = () => {
    const user = { email };
    dispatch(resetpassword(user));
  };
  const alertContent = (msg) => (
    <MDTypography variant="body2" color="white">
      {msg}
    </MDTypography>
  );
  React.useEffect(() => {
    dispatch(clearLoginError());
    dispatch(setBase("user"));
  }, []);
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                onClick={handleReset}
                color="info"
                fullWidth
              >
                reset
              </MDButton>
              {loading && <Loader />}
              {error && (
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDAlert color="error" dismissible>
                    {alertContent(error)}
                  </MDAlert>
                </MDBox>
              )}
              {success && (
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDAlert color="success" dismissible>
                    {alertContent(success)}
                  </MDAlert>
                </MDBox>
              )}
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
