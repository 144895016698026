import * as React from "react";
import Loader from "components/Loader";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useSelector, useDispatch } from "react-redux";
import { contactSupport } from "redux/actions/userActions";

function Support() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.onboardingReducer?.profile);
  const loading = useSelector((state) => state.uiReducer?.loading);
  const error = useSelector((state) => state.uiReducer.error);
  const success = useSelector((state) => state.uiReducer?.success);
  const [firstname, setFirstName] = React.useState(profile.firstname);
  const [lastname, setLastName] = React.useState(profile.surname);
  const [email, setEmail] = React.useState(profile.email);
  const [phone, setPhone] = React.useState(profile.phone);
  const [message, setMessage] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const alertContent = (msg) => (
    <MDTypography variant="body2" color="white">
      {msg}
    </MDTypography>
  );

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSendMessage = () => {
    const msg = {
      sendername: firstname + " " + lastname,
      senderemail: email,
      senderphone: phone,
      subject,
      message,
      company_id: 1,
    };
    dispatch(contactSupport(msg));
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Contact support now
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your message and other details, we will get back to you
              shortly
            </MDTypography>
          </MDBox>
          <MDBox pt={0} pb={1} px={3}>
            <MDBox component="form" role="form">
              <MDBox pb={1} pt={2}>
                <MDInput
                  type="text"
                  label="First Name"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox pb={1}>
                <MDInput
                  type="text"
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastname}
                  fullWidth
                />
              </MDBox>
              <MDBox pb={1}>
                <MDInput
                  type="email"
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  fullWidth
                />
              </MDBox>
              <MDBox pb={1}>
                <MDInput
                  type="phone"
                  value={phone}
                  label="Phone"
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox pb={1}>
                <MDInput
                  type="text"
                  value={subject}
                  label="Subject"
                  onChange={(e) => setSubject(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox pb={1}>
                <MDInput
                  type="text"
                  label="Message"
                  onChange={(e) => setMessage(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
              </MDBox>
              <MDBox
                pb={2}
                pt={2}
                pr={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <MDButton
                  onClick={handleSendMessage}
                  variant="gradient"
                  color="dark"
                >
                  <Icon sx={{ fontWeight: "bold" }}>check</Icon>
                  &nbsp; Send Message
                </MDButton>
              </MDBox>
              <MDBox mt={2} mb={1}>
                {loading && <Loader />}
                {error && (
                  <MDBox mt={2} mb={1} textAlign="center">
                    <MDAlert color="error" dismissible>
                      {alertContent(error)}
                    </MDAlert>
                  </MDBox>
                )}
                {success && (
                  <MDBox mt={2} mb={1} textAlign="center">
                    <MDAlert color="success" dismissible>
                      {alertContent(success)}
                    </MDAlert>
                  </MDBox>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Support;
