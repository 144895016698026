import { useState, useEffect } from "react";
import AddNewVisit from "./AddNewVisit";
import { useSelector } from "react-redux";
import VerifyVisit from "./VerifyVisit";

function Configurator() {
  const logtype = useSelector((state) => state.onboardingReducer?.type);

  return logtype === "user" ? <AddNewVisit /> : <VerifyVisit />;
}

export default Configurator;
