import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";

function Contact() {
  return (
    <>
      <Header />
      <div class="contactus-3">
        {/* <div class="page-header">
          <img
            class="bg-image"
            src="../assets/img/ill/bg_contactus3.svg"
            alt=""
          />
        </div> */}
        <div class="container pt-5">
          <div class="row">
            <div class="col-md-12 text-center mb-5">
              <h1 class="display-1">Got a question?</h1>
              <h3 class="lead">We'd like to talk more about what you need</h3>
              <button class="btn btn-icon btn-primary mt-3" type="button">
                <span class="btn-inner--icon">
                  <i class="ni ni-chat-round"></i>
                </span>
                <span class="btn-inner--text">Chat with us</span>
              </button>
            </div>
          </div>
          <div class="row" style={{display: 'flex', justifyContent: 'center'}}>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="info info-hover">
                <div class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i class="ni ni-email-83"></i>
                </div>
                <h4 class="info-title">Enquiries</h4>
                <p class="description px-0">
                  <a href="mailto: info@smartvisit.ng" class="__cf_email__">
                    info@smartvisit.ng
                  </a>
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="info info-hover">
                <div class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i class="ni ni-circle-08"></i>
                </div>
                <h4 class="info-title">Support</h4>
                <p class="description px-0">support@smartvisit.ng</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="info info-hover">
                <div class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i class="ni ni-mobile-button"></i>
                </div>
                <h4 class="info-title">Phone</h4>
                <p class="description px-0">080 7777 9047, <br /> 0817474 3777</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="container-fluid">
          <div class="row mt-5 mb-4 pt-5">
            <div class="col-md-8 ml-auto mr-auto text-center mt-5">
              <span class="badge badge-info">Leave a message</span>
              <h1 class="title">
                Contact <b>us</b>
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mx-auto ">
              <form
                role="form"
                class="card bg-secondary p-3"
                id="contact-form"
                method="post"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>First name</label>
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="ni ni-circle-08"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="First Name..."
                            aria-label="First Name..."
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Last name</label>
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="ni ni-tag"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Last Name..."
                            aria-label="Last Name..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email address</label>
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-email-83"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email Here..."
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Your message</label>
                    <textarea
                      name="message"
                      class="form-control form-control-alternative"
                      id="message"
                      rows="6"
                    ></textarea>
                  </div>
                  <div class="row">
                    <div class="col-md-6 ml-auto">
                      <button type="submit" class="btn btn-primary pull-right">
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
