export const ACTION_START = "ACTION_START";
export const ACTION_FAILED = "ACTION_FAILED";
export const ACTION_CLEAR = "ACTION_CLEAR";
export const ACTION_SUCCESS = "ACTION_SUCCESS";

export const OPEN_NAV = "OPEN_NAV";
export const CLOSE_NAV = "CLOSE_NAV";
export const DID_TASK = "DID_TASK";

export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";

export const RESET_START = "RESET_START";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAILED = "RESET_FAILED";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const SET_BASE_SUCCESS = "SET_BASE_SUCCESS";
export const OPEN_MESSAGE_SUCCESS = "OPEN_MESSAGE_SUCCESS";
export const CLEAR_MESSAGE_SUCCESS = "CLEAR_MESSAGE_SUCCESS";

export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const CLEAR_LOGIN = "CLEAR_LOGIN";

export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_ADMINUSERS_SUCCESS = "GET_ADMINUSERS_SUCCESS";
export const DELETE_ADMINUSERS_SUCCESS = "DELETE_ADMINUSERS_SUCCESS";

export const CLEAR_USERS_SUCCESS = "CLEAR_USERS_SUCCESS";

export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";

export const ADD_VISIT_START = "ADD_VISIT_START";
export const ADD_VISIT_SUCCESS = "ADD_VISIT_SUCCESS";
export const ADD_VISIT_END = "ADD_VISIT_END";

export const APPROVE_VISIT_START = "APPROVE_VISIT_START";
export const APPROVE_VISIT_SUCCESS = "APPROVE_VISIT_SUCCESS";
export const APPROVE_VISIT_FAILED = "APPROVE_VISIT_FAILED";

export const CHECKOUT_VISIT_START = "CHECKOUT_VISIT_START";
export const CHECKOUT_VISIT_SUCCESS = "CHECKOUT_VISIT_SUCCESS";
export const CHECKOUT_VISIT_FAILED = "CHECKOUT_VISIT_FAILED";

export const DECLINE_VISIT_START = "DECLINE_VISIT_START";
export const DECLINE_VISIT_SUCCESS = "DECLINE_VISIT_SUCCESS";
export const DECLINE_VISIT_FAILED = "DECLINE_VISIT_FAILED";

export const CLEAR_VISIT_SUCCESS = "CLEAR_VISIT_SUCCESS";

export const CONFIRM_VISIT_START = "CONFIRM_VISIT_START";
export const CONFIRM_VISIT_SUCCESS = "CONFIRM_VISIT_SUCCESS";
export const CONFIRM_VISIT_END = "CONFIRM_VISIT_END";

export const CLEAR_CONFIRM = "CLEAR_CONFIRM";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const ADMIN_LOGIN_START = "ADMIN_LOGIN_START";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_END = "ADMIN_LOGIN_END";

export const GET_COMPANIES_START = "GET_COMPANIES_START";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILED = "GET_COMPANIES_FAILED";

export const GET_COMPANYID_START = "GET_COMPANYID_START";
export const GET_COMPANYID_SUCCESS = "GET_COMPANYID_SUCCESS";
export const GET_COMPANYID_FAILED = "GET_COMPANYID_FAILED";

export const ADD_ADMIN_START = "ADD_ADMIN_START";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILED = "ADD_ADMIN_FAILED";

export const GET_VISITS_START = "GET_VISITS_START";
export const GET_VISITS_SUCCESS = "GET_VISITS_SUCCESS";
export const GET_VISITS_FAILED = "GET_VISITS_FAILED";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const SUSPEND_COMPANY_SUCCESS = "SUSPEND_COMPANY_SUCCESS";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const SUSPEND_USER_SUCCESS = "SUSPEND_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const RIGHTOFFERACCEPT_SUCCESS = "RIGHTOFFERACCEPT_SUCCESS";
export const COMPLETE_PAYMENT_SUCCESS = "COMPLETE_PAYMENT_SUCCESS";
export const LOAD_INSTALLMENTS_SUCCESS = "LOAD_INSTALLMENTS_SUCCESS";
export const MAKE_INSTALLMENTS_SUCCESS = "MAKE_INSTALLMENTS_SUCCESS";
export const SEARCH_INVESTMENT_SUCCESS = "SEARCH_INVESTMENT_SUCCESS";
export const CLEAR_LIST_SUCCESS = "CLEAR_LIST_SUCCESS";
