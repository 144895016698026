import { useState, useEffect } from "react";
import "jquery";
import "bootstrap/dist/js/bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";

import "../../assets/css/all.css";
import "../../assets/css/nucleo-icons.css";
import "../../assets/css/nucleo-svg.css";
import "../../assets/css/font-awesome.css";
import "../../assets/css/argon-design-system.min2c70.css";

// @mui icons

import MDTypography from "components/MDTypography";

// Images

import { useDispatch, useSelector } from "react-redux";
import {
  login,
  clearLoginError,
  setBase,
} from "redux/actions/onboardingAction";
import Header from "./components/header";
import Footer from "./components/footer";
import HomeBody from "./homebody";

function Home() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loading = useSelector((state) => state.onboardingReducer.loading);
  const error = useSelector((state) => state.onboardingReducer.error);
  const navigate = useNavigate();
  const dispatchAction = useDispatch();

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      {error}
    </MDTypography>
  );

  useEffect(() => {
    dispatchAction(clearLoginError());
    dispatchAction(setBase("user"));
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleLogin = () => {
    const user = {
      email,
      password,
    };
    if (email.trim().length > 3 && password.trim().length > 3)
      dispatchAction(login(user, navigate));
  };
  return (
    <div>
      <Header />
      <div className="wrapper">
        <HomeBody />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
