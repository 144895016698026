function HomeBody({ coverHeight, image, children }) {
  return (
    <>
      <div className="section section-hero section-shaped pt-0">
        <div className="page-header mt-n5">
          <div
            className="page-header-image"
            style={{
              background: `url("./assets/img/banner.jpeg")`,
              backgroundSize: "contain",
              backgroundPosition: "100%",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="container-fluid shape-container d-flex align-items-center py-lg">
            <div className="col px-0">
              <div className="row">
                <div className="col-lg-4 ml-5" style={{ position: "relative" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: 35,
                      marginRight: 5,
                    }}
                  >
                    SmartVisit
                  </span>

                  <p className="lead">
                    ENHANCING VISITOR EXPERIENCE WITH ENSURING PREMISES SECURITY
                    {/* <br /> <b>Smartvisit Visitor Management System.</b> */}
                  </p>
                  <div className="btn-wrapper mt-5">
                    <img
                      width={150}
                      height={50}
                      style={{ marginRight: 10 }}
                      src={"./assets/img/playstore.png"}
                    />

                    <img
                      height={50}
                      width={150}
                      src={"./assets/img/appstore.png"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-info">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="info text-left">
                <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                  <i className="ni ni-user-run"></i>
                </div>
                <h6 className="info-title text-uppercase text-primary pl-0">
                  Easy Onboarding and Signup Process
                </h6>
                <p className="description opacity-8">
                  Your Organization/Estate can be onboarded within minutes and
                  Staffs/Tenants can sign up under your organization's profile
                  immediately.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info text-left" style={{ marginTop: "-50px" }}>
                <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
                  <i className="ni ni-atom"></i>
                </div>
                <h6 className="info-title text-uppercase text-success pl-0">
                  Fast & Seamless Visitor's Enrolment
                </h6>
                <p className="description opacity-8">
                  Register your visit within seconds and get a visitors code
                  generated and sent to your guest seamlessly.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info text-left">
                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                  <i className="ni ni-istanbul"></i>
                </div>
                <h6 className="info-title text-uppercase text-warning pl-0">
                  Quick Verification & Entry Process
                </h6>
                <p className="description opacity-8">
                  Visitor is verified swiftly and without any hassles at the
                  entry point and visitors record stored automatically for
                  report purposes and query.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-md-5 order-md-2 order-lg-1">
              <div className="image-container">
                <img
                  className="img shadow rounded img-comments w-100"
                  src="assets/img/howitworks.jpeg"
                />
              </div>
            </div>
            <div className="col-lg-6 mx-auto order-md-1">
              <div className="section-description">
                <h1 className="display-3">
                  How it works
                  {/* <span className="text-danger">
                    {" "}
                    For Entries that Need More Security
                  </span> */}
                </h1>
                <p className="lead">
                  SmartVisit® is designed to foster better interaction within
                  organisations with strict security protocols and workplaces
                  that are highly confidential. Users can register their
                  visitor’s and send an entry code to the visitor, either by
                  text or QR code, the security at the gate verifies this code
                  on the visitor’s arrival and then entry is approved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  <section className="section-features bg-secondary">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 pt-5">
              <div className="info info-horizontal">
                <div className="icon icon-shape icon-shape-warning rounded-circle text-white">
                  <i className="ni ni-money-coins text-warning"></i>
                </div>
                <div className="description pl-4 pt-2">
                  <h5 className="title">Save Time & Money</h5>
                  <p>
                    Creating your design from scratch with dedicated designers
                    can be very expensive.Using Argon Design System PRO you
                    don't have to worry about customising the basic Bootstrap 4
                    design or its components.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-bold text-info"></i>
                </div>
                <div className="description pl-4 pt-2">
                  <h5 className="title">Bootstrap 4 & Flexbox</h5>
                  <p>
                    It is built over Bootstrap 4, it's fully responsive and has
                    all the benefits of the flexbox for the layout, grid system
                    and components. This is a huge advantage when you work with
                    columns.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-shape icon-shape-danger rounded-circle text-white">
                  <i className="ni ni-paper-diploma text-danger"></i>
                </div>
                <div className="description pl-4 pt-2">
                  <h5 className="title">Fast Prototype</h5>
                  <p>
                    Using Argon Design System PRO you can create hundreds of
                    components combinations within seconds and present them to
                    your client. You just need to change some classNamees and
                    colors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="image-container">
                <img
                  className="w-100"
                  src="assets/img/presentation-page/ipad.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
     <section className="section-testimonials mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto text-center">
              <h2 className="display-2 mb-5">Trusted by 880,000+ People</h2>
              <p className="lead">
                The UI Kits, Templates and Dashboards that we've created are
                used by{" "}
                <strong className="text-primary">
                  880,000+ web developers
                </strong>{" "}
                in over{" "}
                <strong className="text-primary">1,500,000 Web Projects</strong>
                . This is what some of them think:
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-8 mx-auto">
              <div id="carouselExampleIndicatoru" className="carousel slide">
                <ol className="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicatoru"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicatoru"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicatoru"
                    data-slide-to="2"
                  ></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active justify-content-center">
                    <div className="card card-testimonial card-plain">
                      <div className="card-body text-center">
                        <p className="card-description">
                          "Awesome Design and very well organized code
                          structure! Also, it contains numerous elements using
                          which achieving the perfect or required template can
                          be done with ease. Great job!"
                        </p>
                        <h4 className="card-title">Stefan</h4>
                        <h6 className="category text-muted">Web Designer</h6>
                        <div className="card-footer">
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item justify-content-center">
                    <div className="card card-testimonial card-plain">
                      <div className="card-avatar">
                        <a href="#pablo">
                          <img
                            className="img img-raised rounded"
                            src="https://s3.amazonaws.com/uifaces/faces/twitter/chadengle/128.jpg"
                          />
                        </a>
                      </div>
                      <div className="card-body text-center">
                        <p className="card-description">
                          "It looks great and its somewhat futuristics cant wait
                          to use it on a project here in nigeria i'm sure it
                          would put me ahead.. I cant wait to hv enough money to
                          buy ur product."
                        </p>
                        <h4 className="card-title">Mr. Bones</h4>
                        <h6 className="category text-muted">Web Designer</h6>
                        <div className="card-footer">
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item justify-content-center">
                    <div className="card card-testimonial card-plain">
                      <div className="card-avatar">
                        <a href="#pablo">
                          <img
                            className="img img-raised rounded"
                            src="https://s3.amazonaws.com/creativetim_bucket/photos/123124/thumb.?1480480048"
                          />
                        </a>
                      </div>
                      <div className="card-body text-center">
                        <p className="card-description">
                          "Everything is perfect. Codes are really organized.
                          It's easy to edit for my own purposes. It's great that
                          it is built on top of Bootstrap 4."
                          <br />
                          <br />
                        </p>
                        <h4 className="card-title">DOĞA</h4>
                        <h6 className="category text-muted">Web Developer</h6>
                        <div className="card-footer">
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                          <i className="fa fa-star text-warning"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicatoru"
              role="button"
              data-slide="prev"
            >
              <i className="ni ni-bold-left"></i>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicatoru"
              role="button"
              data-slide="next"
            >
              <i className="ni ni-bold-right"></i>
            </a>
          </div>
        </div>
      </section>
      <div className="section section-pricing" id="sectionBuy">
        <div className="container">
          <div className="row our-clients">
            <div className="col-lg-3 col-md-6 col-6">
              <img
                className="w-50"
                src="assets/img/presentation-page/harvard.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <img
                className="w-50"
                src="assets/img/presentation-page/microsoft.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <img
                className="w-50"
                src="assets/img/presentation-page/vodafone.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <img
                className="w-50"
                src="assets/img/presentation-page/stanford.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default HomeBody;
