import { useState, useEffect, useMemo } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Verified";
import MDBox from "components/MDBox";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import PrivateRoute from "PrivateRoute";

import routes from "routes";

import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { getCompanyID } from "redux/actions/onboardingAction";
import MDTypography from "components/MDTypography";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const dispatchAction = useDispatch();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const logstatus = useSelector((state) => state.onboardingReducer?.logstatus);
  const logtype = useSelector((state) => state.onboardingReducer?.type);
  const role = useSelector((state) => state.onboardingReducer?.profile?.type);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  const dem = true;

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.isAdmin && logtype === "user") {
        return;
      }
      if (route.AdminLevel === 1 && role === 0) {
        return;
      } else if (route.AdminLevel === 2 && logtype === "user") {
        return;
      }
      if (route.key === "support" && role === 0) {
        return;
      } else if (route.key === "invite-guest" && role === 0) {
        return;
      } else if (route.key === "companies" && role !== 0) {
        return;
      } else if (route.key === "admin-users" && role === 2) {
        return;
      } else if (route.key === "notifications" && role === 2) {
        return;
      } else if (route.key === "support" && role === 2) {
        return;
      } else if (route.key === "messages" && role === 2) {
        return;
      } else if (route.key === "visit-logs" && role === 2) {
        return;
      } else if (route.key === "users" && role === 2) {
        return;
      } // else if (route.key === "users" && role === 0) {
      //   return;
      // }

      if (route.route && route.name !== "SignIn") {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.isPrivate ? (
                <PrivateRoute>{route.component}</PrivateRoute>
              ) : (
                route.component
              )
            }
            key={route.key}
          />
        );
      }
    });

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="SmartVisit"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Routes>
        {getRoutes(routes)}
        {/* <Route
          path="*"
          element={<Navigate to={logstatus ? "/dashboard" : "/login"} />}
        /> */}
      </Routes>
      <CssBaseline />
      {layout === "dashboard" && logstatus ? (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="SmartVisit"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
          >
            {logtype === "user" ? (
              <Fab
                sx={{ backgroundColor: "#8A624A", color: "white", width: 200 }}
                aria-label="add"
              >
                <AddIcon color="white" fontSize="large" />
                <MDTypography color={"white"}>Invite Guest</MDTypography>
              </Fab>
            ) : (
              <Fab
                sx={{ backgroundColor: "#8A624A", color: "white", width: 200 }}
                aria-label="verify"
              >
                <SearchIcon color="white" fontSize="medium" />
                <MDTypography color={"white"}>Verify Guest</MDTypography>
              </Fab>
            )}
          </MDBox>
        </>
      ) : null}
      {layout === "vr" && <Configurator />}
    </ThemeProvider>
  );
}
